import { useContext, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  notification,
  Card,
  Divider,
  Modal,
  Switch,
  Tooltip,
  theme,
  AutoComplete,
  Checkbox,
  Select,
  Tour,
  Flex,
} from 'antd';
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  ExclamationCircleFilled,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import { axiosInstance } from '../../../utils/axios';
import { validatePhoneNumber, validateEmail } from '../../../utils/validators';
import DashboardLayout from '../../../components/layout/dashboard';
import SelectScreeningSteps from '../../../components/SelectScreeningSteps';
import Paragraph from 'antd/es/typography/Paragraph';
import VOGTypes from '../../../components/screening-steps/CertificateOfConductStep/VOGTypes.json';
import useDynamicRefs from '../../../hooks/useDynamicRefs';
import { useStartScreeningTour } from '../../../hooks/tour/useStartScreeningTour';
import { DashboardPageContentContext } from '../../../context/DashboardContentProvider';
import LoadingSpinner from '../../../components/screening-steps/util/LoadingSpinner';
import '../../../styles/dashboard.css';

const { confirm } = Modal;
export interface SelectSteps {
  identification: boolean;
  bankData: boolean;
  incomeCheck: boolean;
  kvk: boolean;
  bkr: boolean;
  kadaster: boolean;
  referent: boolean;
  landlordStatement: boolean;
  employeeIntegrity: boolean;
  education: boolean;
  certificateOfConduct: boolean;
}

const { useToken } = theme;

const StartScreeningScreen = () => {
  useTitle('Start Screening');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [startScreeningLoading, setStartScreeningLoading] = useState(false);
  const [cost, setCost] = useState(1);
  const [interpretationOptions, setInterpretationOptions] = useState<any[]>([]);
  const [selectSteps, setSelectSteps] = useState<SelectSteps>({
    identification: false,
    bankData: false,
    incomeCheck: false,
    kvk: false,
    bkr: false,
    kadaster: false,
    referent: false,
    landlordStatement: false,
    employeeIntegrity: false,
    education: false,
    certificateOfConduct: false,
  });
  const [interpretation, setInterpretation] = useState<boolean>(false);
  const [expertInterpretation, setExpertInterpretation] =
    useState<boolean>(false);

  // tour variables
  const [getRef, setRef] = useDynamicRefs<HTMLElement>();
  const { tourSteps, tourOpen, setTourOpen } = useStartScreeningTour(getRef);

  const [duidingEmail, setDuidingEmail] = useState<string>('');
  const { token } = useToken();

  const content = useContext(DashboardPageContentContext);

  const { landlordStatement, employeeIntegrity, ...stepsForAllSelected } =
    selectSteps;

  const allSelected = Object.values(stepsForAllSelected).every(
    (value) => value === true,
  );

  const noneSelected = Object.values(stepsForAllSelected).every(
    (value) => value === false,
  );

  const howManySelected = Object.values(stepsForAllSelected).filter(
    (value) => value === true,
  ).length;

  const changeAllSelected = () => {
    setSelectSteps((prev) => ({
      ...prev,
      identification: !allSelected,
      bankData: !allSelected,
      incomeCheck: !allSelected,
      kvk: !allSelected,
      bkr: !allSelected,
      kadaster: !allSelected,
      referent: !allSelected,
      education: !allSelected,
      certificateOfConduct: !allSelected,
    }));
  };

  const SubmitForm = (values: Fields) => {
    setStartScreeningLoading(true);
    const { referent, ...configObjectToSubmit } = selectSteps;
    axiosInstance
      .post('/screening/start', {
        ...values,
        expertInterpretation: expertInterpretation,
        config: configObjectToSubmit,
      })
      .then(() => {
        setStartScreeningLoading(false);
        notificationApi.success({
          message: 'Screening aangemaakt',
          placement: 'bottomRight',
          type: 'success',
        });
      })
      .catch((error) => {
        setStartScreeningLoading(false);

        if (error.response?.status === 409) {
          notificationApi.error({
            message: `${error.response.data.message}`,
            placement: 'bottomRight',
            type: 'error',
          });
        } else if (error.response?.data?.message === 'Not enough credits') {
          notification.error({
            message:
              'Er zijn niet genoeg credits aanwezig om deze screening aan te maken.',
          });
        } else {
          notification.error({
            message: `Er is een fout opgetreden, probeer het later opnieuw.`,
            placement: 'bottomRight',
            type: 'error',
          });
        }
      });
  };

  useEffect(() => {
    axiosInstance
      .get('/company/interpretation-suggestions')
      .then((response) => {
        setInterpretationOptions(
          response.data.map((option: any) => ({
            value: option,
          })),
        );
      })
      .catch(() => {});
  }, []);

  const onFinish = async (values: Fields) => {
    if (
      selectSteps.referent &&
      !selectSteps.landlordStatement &&
      !selectSteps.employeeIntegrity
    ) {
      notificationApi.error({
        message: 'Selecteer een type voor de referentiecheck',
        description: 'Selecteer een referent type om door te gaan.',
        placement: 'bottomRight',
      });
      return;
    }
    if (noneSelected) {
      confirm({
        title: 'Doorgaan zonder checks?',
        icon: <ExclamationCircleFilled />,
        content:
          'U staat op het punt om een screening aan te maken zonder checks, weet u zeker dat u dit wilt doen? Het is mogelijk om door te gaan zonder checks, de kandidaat zal dan alleen zijn persoonsgegevens in moeten vullen.',
        okText: 'Ja',
        cancelText: 'Annuleren',
        onOk() {
          SubmitForm(values);
        },
        onCancel() {
          return;
        },
      });
    }
    if (howManySelected === 1) {
      confirm({
        title: 'Screening aanmaken met 1 check?',
        icon: <ExclamationCircleFilled />,
        content:
          'U staat op het punt om een screening aan te maken met 1 check, weet u zeker dat u dit wilt doen?',
        okText: 'Ja',
        cancelText: 'Annuleren',
        onOk() {
          SubmitForm(values);
        },
        onCancel() {
          return;
        },
      });
    }
    if (howManySelected > 1) {
      SubmitForm(values);
    }
    return;
  };

  interface Fields {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    interpretation: boolean;
    interpretationEmail: string | null;
  }

  return content === 'loading' ? (
    <LoadingSpinner />
  ) : (
    <DashboardLayout setTourOpen={setTourOpen}>
      {contextHolder}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card
          title="Start een screening"
          style={{ marginTop: 20, maxWidth: 750, width: '100%' }}
        >
          <Form
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            size={'middle'}
          >
            <Flex vertical ref={setRef('general')}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Vul alstublieft een voornaam in.',
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Voornaam"
                  maxLength={2000}
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Vul alstublieft een achternaam in.',
                  },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Achternaam"
                  maxLength={2000}
                />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: 'Vul alstublieft een mobiel nummer in.',
                  },
                  { validator: validatePhoneNumber },
                ]}
                style={{ marginBottom: 20 }}
              >
                <Input
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                  placeholder="Mobiel nummer"
                  maxLength={2000}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Vul alstublieft een email in.' },
                  { validator: validateEmail },
                ]}
                style={{ marginBottom: 24 }}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="email@provider.com"
                  maxLength={2000}
                />
              </Form.Item>
            </Flex>
            <Flex vertical ref={setRef('interpretation')}>
              <Form.Item
                name="interpretation"
                className="no-margin"
                initialValue={false}
                label={
                  <>
                    {content.startScreening.clarificationTitle}
                    <div
                      style={{
                        display: 'block',
                        width: '20rem',
                      }}
                    >
                      <Tooltip
                        title={
                          <div>{content.startScreening.clarificationInfo}</div>
                        }
                        overlayStyle={{ maxWidth: '100%' }}
                        color={token.colorPrimary}
                      >
                        <InfoCircleOutlined style={{ marginLeft: '1%' }} />
                      </Tooltip>
                    </div>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message:
                      'Geef alstublieft aan of u extra duiding wil bij de screening.',
                  },
                ]}
              >
                <Switch
                  defaultChecked={false}
                  onChange={(checked) => {
                    setInterpretation(!interpretation);
                  }}
                />
              </Form.Item>
            </Flex>

            {interpretation && (
              <>
                <Checkbox
                  style={{ marginBottom: 20 }}
                  checked={expertInterpretation}
                  onChange={(checked) => {
                    setExpertInterpretation(checked.target.checked);
                    setCost(checked.target.checked ? cost + 4 : cost - 4);
                  }}
                >
                  <div>
                    <span
                      style={{
                        display: 'block',
                      }}
                    >
                      {content.startScreening.expertClarificationTitle}
                    </span>
                    <Tooltip
                      title={
                        <div>
                          {content.startScreening.expertClarificationInfo}
                        </div>
                      }
                      color={token.colorPrimary}
                    >
                      <InfoCircleOutlined style={{ marginLeft: '1%' }} />
                    </Tooltip>
                  </div>
                </Checkbox>

                <Form.Item
                  name="interpretationEmail"
                  rules={[
                    {
                      validator: validateEmail,
                    },
                  ]}
                  validateFirst
                >
                  <AutoComplete
                    options={interpretationOptions}
                    value={duidingEmail}
                    disabled={expertInterpretation}
                  >
                    <Input
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      disabled={expertInterpretation}
                      placeholder="Email duiding"
                      onChange={(value) => setDuidingEmail(value.target.value)}
                      value={duidingEmail}
                      maxLength={2000}
                    />
                  </AutoComplete>
                </Form.Item>
              </>
            )}
            <Divider />
            <Flex vertical ref={setRef('checks')}>
              <SelectScreeningSteps
                selectSteps={selectSteps}
                setSelectSteps={setSelectSteps}
                allSelected={allSelected}
                changeAllSelected={changeAllSelected}
              />
            </Flex>
            {selectSteps.certificateOfConduct && (
              <div style={{ marginLeft: 40 }}>
                <p>
                  <b>Type VOG</b>
                  <Tooltip
                    title={<div>{content.startScreening.cocTypeInfo}</div>}
                    overlayStyle={{ maxWidth: '100%' }}
                    color={token.colorPrimary}
                  >
                    <InfoCircleOutlined style={{ marginLeft: '1%' }} />
                  </Tooltip>
                </p>
                <Form.Item
                  name="certificateOfConductType"
                  rules={[
                    {
                      required: true,
                      message:
                        'Selecteer het type VOG dat aangevraagd zal worden',
                    },
                  ]}
                >
                  <Select options={VOGTypes} />
                </Form.Item>
              </div>
            )}
            <Form.Item>
              <Paragraph>
                Het starten van deze screening kost{' '}
                <strong>
                  {cost > 1 ? `${cost} credits` : `${cost} credit`}
                </strong>
                .
              </Paragraph>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: '100%' }}
                loading={startScreeningLoading}
              >
                Start screening nu
              </Button>
            </Form.Item>
          </Form>
          <Button onClick={() => navigate('/dashboard')}>
            Terug naar dashboard
          </Button>
        </Card>
      </div>
      <Tour
        open={tourOpen}
        onClose={() => setTourOpen(false)}
        steps={tourSteps}
      />
    </DashboardLayout>
  );
};

export default StartScreeningScreen;
