import { useContext, useEffect, useState } from 'react';
import { disableDashboardTour } from '../../utils/dashboardTourFunctions';
import { TourProps } from 'antd';
import { useMediaQuery } from '../useMediaQuery';
import { DashboardPageContentContext } from '../../context/DashboardContentProvider';

export function useDashBoardHomeTour(
  getRef: (key: string) => React.RefObject<HTMLElement>,
) {
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>();
  const [tourOpen, setTourOpen] = useState(false);
  const content = useContext(DashboardPageContentContext);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (content === 'loading') return;
    setTourSteps([
      {
        title: content.screenings.creditsTutorialTitle,
        description: content.screenings.creditsTutorialDescription,
        target: getRef('credits')?.current,
      },
      {
        title: content.screenings.startScreeningTutorialTitle,
        description: content.screenings.startScreeningTutorialDescription,
        target: getRef('start-screening')?.current,
      },
      {
        title: content.screenings.screeningTableTutorialTitle,
        description: content.screenings.screeningTableDescription,
        target: getRef('screening-table')?.current,
      },
    ]);

    if (localStorage.getItem('tour-dashboard-home') && !isMobile) {
      setTourOpen(true);
      disableDashboardTour('tour-dashboard-home');
    }
  }, [isMobile, getRef, content]);

  return { tourSteps, setTourOpen, tourOpen };
}
