import React, { useCallback, useContext, useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axios';
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Input,
  Row,
  Flex,
} from 'antd';
import {
  DeleteFilled,
  CheckCircleTwoTone,
  ClockCircleTwoTone,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ScreeningStep } from '../../redux/slices/steps.slice';
import { availableSteps } from '../../utils/allSteps';
import { Steps } from '../../shared/enums/steps.enum';
import { DateTime } from 'luxon';
import LoadingSpinner from '../screening-steps/util/LoadingSpinner';
import { ScreeningFilterButton, Filter } from './ScreeningFilterButton';
import { DashboardPageContentContext } from '../../context/DashboardContentProvider';
const { Column } = Table;

interface Screening {
  id: String;
  createdAt: String;
  status: String;
  candidate: {
    firstName: String;
    lastName: String;
    email: String;
  };
  screeningCompleted: boolean;
}

const ScreeningOverviewTable = () => {
  const [screenings, setScreenings] = useState<Screening[]>([]);
  const [filteredData, setFilteredData] = useState<Screening[]>([]);
  const [loadingGenerateReportId, setLoadingGenerateReportId] =
    useState<string>();
  const [messageApi, contextHolder] = notification.useNotification();
  const [toggleFilter, setToggleFilter] = useState(Filter.All);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const content = useContext(DashboardPageContentContext);

  useEffect(() => {
    axiosInstance
      .get('screening/getall')
      .then((response) => {
        setScreenings(response.data);
        setLoadingData(false);
        setFilteredData(response.data);
      })
      .catch(() => {
        setLoadingData(false);
      });
  }, [setScreenings]);

  const recordMatchesSearch = useCallback(
    (record: any) => {
      const fullName =
        `${record.candidate.firstName} ${record.candidate.lastName}`.toLowerCase();
      const searchValue = search.toLowerCase();
      return (
        record.candidate.email.toLowerCase().includes(searchValue) ||
        fullName.includes(searchValue)
      );
    },
    [search],
  );

  useEffect(() => {
    let filteredData;

    if (toggleFilter === Filter.All) {
      filteredData = screenings.filter((record) => recordMatchesSearch(record));
      setFilteredData(filteredData);
      return;
    }

    filteredData = screenings
      .filter((record) => {
        switch (toggleFilter) {
          case Filter.NotStarted:
            return record.status === Steps.notStarted;
          case Filter.InProgress:
            return (
              record.status !== Steps.notStarted &&
              record.status !== Steps.completed
            );
          case Filter.Completed:
            return record.status === Steps.completed;
          default:
            return false;
        }
      })
      .filter((record) => recordMatchesSearch(record));

    setFilteredData(filteredData);
  }, [toggleFilter, screenings, search, recordMatchesSearch]);

  const formatDate = (timestamp: string): string => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const date = new Date(timestamp);
    return date.toLocaleString('nl-NL', dateOptions);
  };

  const formatStatus = (status: string): string => {
    const step = availableSteps.find(
      (step: ScreeningStep) => step.url === status,
    );
    if (step) {
      return step.title;
    }

    if (status === Steps.notStarted) {
      return 'Nog niet gestart';
    }

    if (status === Steps.completed) {
      return 'Afgerond';
    }

    return '';
  };

  const generateRapport = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    screeningId: string,
  ) => {
    e.stopPropagation();
    setLoadingGenerateReportId(screeningId);
    axiosInstance
      .get('/screening/generateReport/' + screeningId)
      .then((response) => {
        setLoadingGenerateReportId('');
        window.open(response.data[0]);
      })
      .catch((error) => {
        messageApi.error({
          message: 'Er is iets misgegaan bij het genereren van het rapport',
          placement: 'bottomRight',
        });
        setLoadingGenerateReportId('');
      });
  };

  const deleteScreening = (
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    screeningId: string,
  ) => {
    if (e) {
      e.stopPropagation();
    }
    axiosInstance
      .get('/screening/delete/' + screeningId)
      .then((response) => {
        messageApi.success({
          message: 'Screening is succesvol verwijderd',
          placement: 'bottomRight',
        });

        setScreenings((prevScreenings) => {
          if (!prevScreenings) return [];
          return prevScreenings.filter(
            (screening) => screening.id !== screeningId,
          );
        });
        setFilteredData((prevScreenings) => {
          if (!prevScreenings) return [];
          return prevScreenings.filter(
            (screening) => screening.id !== screeningId,
          );
        });
      })
      .catch(() => {
        messageApi.error({
          message: 'Er is iets fout gegaan met het verwijderen.',
          placement: 'bottomRight',
        });
      });
  };

  return loadingData || content === 'loading' ? (
    <LoadingSpinner />
  ) : (
    <>
      {contextHolder}
      <Flex vertical>
        <Row justify="end" style={{ marginBottom: '1rem' }}>
          <ScreeningFilterButton
            filter={toggleFilter}
            setFilter={setToggleFilter}
          />
          <Input.Search
            placeholder="Zoek screening"
            style={{ width: 250 }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Row>
        <Table
          dataSource={filteredData}
          rowKey="id"
          scroll={{ x: true }}
          onRow={(record, index) => {
            return {
              onClick: (event) => {
                navigate(`/dashboard/screening/${record.id}`);
              },
              style: {
                cursor: 'pointer',
              },
            };
          }}
          sortDirections={['ascend', 'descend']}
        >
          <Column
            title="Status"
            key="completed"
            dataIndex="screeningCompleted"
            width="5%"
            render={(completed: boolean) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {completed ? (
                  <CheckCircleTwoTone
                    twoToneColor="#26d151"
                    style={{ fontSize: '20px' }}
                  />
                ) : (
                  <ClockCircleTwoTone
                    twoToneColor="orange"
                    style={{ fontSize: '20px' }}
                  />
                )}
              </div>
            )}
          />
          <Column
            title="Gestart op"
            dataIndex="createdAt"
            width="20%"
            render={(timestamp: string) => {
              return formatDate(timestamp);
            }}
            sorter={(a: Screening, b: Screening) => {
              return (
                DateTime.fromISO(a.createdAt as string).toMillis() -
                DateTime.fromISO(b.createdAt as string).toMillis()
              );
            }}
            defaultSortOrder={'descend'}
          />
          <Column
            title="Voornaam"
            key="firstName"
            dataIndex={['candidate', 'firstName']}
            width="20%"
          />
          <Column
            title="Achternaam"
            key="lastName"
            dataIndex={['candidate', 'lastName']}
            width="20%"
          />
          <Column
            title="Email"
            key="email"
            dataIndex={['candidate', 'email']}
            width="20%"
          />
          <Column
            title="Voortgang"
            key="status"
            dataIndex="status"
            width="20%"
            render={(statusUrl: string) => {
              return formatStatus(statusUrl);
            }}
          />
          <Column
            title="Rapport"
            key="rapport"
            dataIndex="id"
            width="20%"
            render={(screeningId: string) => {
              const screening = screenings?.find((s) => s.id === screeningId);
              const isScreeningCompleted =
                screening?.screeningCompleted || false;
              const buttonText = isScreeningCompleted
                ? 'Bekijk eindrapport'
                : 'Genereer concept rapport';

              return (
                <div>
                  <Button
                    onClick={(e) => generateRapport(e, screeningId)}
                    loading={loadingGenerateReportId === screeningId}
                    style={{ zIndex: 5 }}
                  >
                    {buttonText}
                  </Button>
                </div>
              );
            }}
          />
          <Column
            title=""
            key="deleteButton"
            dataIndex="id"
            width="5%"
            render={(screeningId: string) => (
              <div>
                <Popconfirm
                  overlayStyle={{ width: '30em' }}
                  title={content.screenings.deleteScreeningTitle}
                  description={content.screenings.deleteScreeningDescription}
                  okText="Ja"
                  cancelText="Nee"
                  onConfirm={(e) => deleteScreening(e, screeningId)}
                  onCancel={(e) => e?.stopPropagation()}
                >
                  <Button
                    onClick={(e) => e.stopPropagation()}
                    danger
                    icon={<DeleteFilled />}
                    size="small"
                    style={{ zIndex: 5 }}
                  ></Button>
                </Popconfirm>
              </div>
            )}
          />
        </Table>
      </Flex>
    </>
  );
};

export default ScreeningOverviewTable;
