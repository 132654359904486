import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
  useContext,
} from 'react';
import {
  Form,
  Input,
  Radio,
  Button,
  RadioChangeEvent,
  Card,
  Divider,
} from 'antd';
import { DatePicker } from '../DatePicker';
import { axiosInstance } from '../../utils/axios';
import { validatePostcode } from '../../utils/validators';
import { DateTime } from 'luxon';
import { useStep } from '../../hooks/useStep';
import { useTitle } from '../../hooks/useTitle';
import { useMessage } from '../../hooks/useMessage';
import LoadingSpinner from './util/LoadingSpinner';
import CommentField from '../CommentField/CommentField';
import axios from 'axios';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { ScreeningContentContext } from '../../context/ScreeningContentProvider';

interface PersonaInputs {
  street: string;
  houseNumber: string;
  postcode: string;
  place: string;
  dateOfBirth: string;
  placeOfBirth: string;
  personaType: string;
  commercialUse: boolean;
}

enum PersonaType {
  PRIVATE = 'private',
  COMMERCIAL = 'commercial',
}

const PersonaStep = forwardRef((props, ref) => {
  useTitle('Persoonlijke gegevens');
  const { toast } = useMessage();
  const [form] = Form.useForm();
  const [displayObjectCommercially, setDisplayObjectCommercially] =
    useState(false);
  const { goToNextStep } = useStep();
  const [comment, setComment] = useState<string>('');

  const [loadingData, setLoadingData] = useState(true);
  const content = useContext(ScreeningContentContext);
  const loadingContent = content === 'loading';
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const onPersonTypeChange = (event: RadioChangeEvent) => {
    if (event.target.value === PersonaType.COMMERCIAL) {
      return setDisplayObjectCommercially(true);
    }
    return setDisplayObjectCommercially(false);
  };

  useImperativeHandle(ref, () => ({
    save() {
      const values = form.getFieldsValue();
      const touched = form.isFieldsTouched();
      if (!touched) {
        return;
      }
      form
        .validateFields({ validateOnly: true })
        .then(() => {
          onFinish(values);
        })
        .catch(() => {});
    },
  }));

  const onFinish = (values: PersonaInputs) => {
    setLoading(true);
    if (
      values.personaType === PersonaType.PRIVATE ||
      values.commercialUse == null
    ) {
      values.commercialUse = false;
    }

    axiosInstance
      .post('/screening/personaDetails', values)
      .then(() => {
        toast.success({
          content: 'Gegevens opgeslagen',
        });
        goToNextStep();
      })
      .catch((error) => {
        toast.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw.`,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    axiosInstance
      .get('/screening/personaDetails/')
      .then((response) => {
        if (response.data) {
          setComment(response.data.comment);
          form.setFieldsValue({
            touched: false,
            street: response.data.street,
            houseNumber: response.data.houseNumber,
            postcode: response.data.postcode,
            place: response.data.place,
            dateOfBirth: DateTime.fromISO(response.data.dateOfBirth),
            placeOfBirth: response.data.placeOfBirth,
            personaType: response.data.personaType,
            commercialUse: response.data.commercialUse,
          });

          if (response.data.commercialUse) {
            setDisplayObjectCommercially(true);
          }
        }
        setLoadingData(false);
      })
      .catch((error) => {
        toast.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw.`,
        });
        setLoadingData(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchAdress = async (postcode: string, houseNumber: string) => {
    try {
      const { data } = await axios.get(
        `https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?q=${postcode} ${houseNumber}&wt=json`,
      );

      if (data) {
        const adres = data.response.docs[0];

        if (!(adres.huisnummer === +houseNumber && adres.postcode === postcode))
          return;

        if (adres) {
          form.setFieldsValue({
            street: adres?.straatnaam,
            place: adres?.woonplaatsnaam,
          });
        }
      }
    } catch (error) {}
  };

  return (
    <>
      {loadingData || loadingContent ? (
        <LoadingSpinner />
      ) : (
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Card
            title={content.personaDetails.title}
            style={{ maxWidth: 500, width: '100%' }}
          >
            <Form.Item label="Postcode en huisnummer">
              <Input.Group compact>
                <Form.Item
                  label="Postcode"
                  name="postcode"
                  noStyle
                  rules={[
                    { required: true, message: 'Postcode is verplicht' },
                    { validator: validatePostcode },
                  ]}
                >
                  <Input
                    placeholder="Postcode"
                    style={{ width: isMobile ? '50%' : '70%' }}
                    onChange={(e) => {
                      const re = new RegExp('^[0-9]{4}[A-Za-z]{2}$');
                      if (
                        !re.test(e.target.value) &&
                        form.getFieldValue('houseNumber')
                      ) {
                        searchAdress(
                          e.target.value,
                          form.getFieldValue('houseNumber'),
                        );
                      }
                    }}
                    maxLength={10}
                  />
                </Form.Item>

                <Form.Item
                  name="houseNumber"
                  noStyle
                  rules={[
                    { required: true, message: 'Huisnummer is verplicht' },
                  ]}
                >
                  <Input
                    style={{ width: isMobile ? '50%' : '30%' }}
                    placeholder="Huisnummer"
                    onChange={(e) => {
                      if (e.target.value && form.getFieldValue('postcode')) {
                        searchAdress(
                          form.getFieldValue('postcode'),
                          e.target.value,
                        );
                      }
                    }}
                    maxLength={10}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item
              label="Straat"
              name="street"
              rules={[{ required: true, message: 'Straat is verplicht' }]}
            >
              <Input placeholder="Straat" maxLength={1000} />
            </Form.Item>

            <Form.Item
              label="Woonplaats"
              name="place"
              rules={[{ required: true, message: 'Woonplaats is verplicht' }]}
            >
              <Input placeholder="Woonplaats" maxLength={1000} />
            </Form.Item>

            <Form.Item
              label="Geboortedatum"
              name="dateOfBirth"
              rules={[
                { required: true, message: 'Geboortedatum is verplicht' },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" placeholder="01-01-1990" />
            </Form.Item>

            <Form.Item
              label="Geboorteplaats"
              name="placeOfBirth"
              rules={[
                { required: true, message: 'Geboorteplaats is verplicht' },
              ]}
            >
              <Input placeholder="Geboorteplaats" maxLength={1000} />
            </Form.Item>

            <Form.Item
              label="Ik ben een:"
              name="personaType"
              rules={[
                {
                  required: true,
                  message: 'Kies tussen Particulier of Zakelijk',
                },
              ]}
            >
              <Radio.Group onChange={onPersonTypeChange}>
                <Radio value={PersonaType.PRIVATE}>Particulier</Radio>
                <Radio value={PersonaType.COMMERCIAL}>Zakelijk</Radio>
              </Radio.Group>
            </Form.Item>
            {displayObjectCommercially && (
              <Form.Item
                label="Wilt u dit object zakelijk gebruiken?"
                name="commercialUse"
              >
                <Radio.Group>
                  <Radio value={true}>Ja</Radio>
                  <Radio value={false}>Nee</Radio>
                </Radio.Group>
              </Form.Item>
            )}
            <Divider />
            <CommentField
              ApiUrl={'screening/personaDetails/comment'}
              comment={comment}
            />
          </Card>
          <Form.Item>
            <Button
              style={{
                float: 'right',
                marginTop: 20,
                marginBottom: 30,
                marginRight: 10,
              }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Volgende
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
});

export default PersonaStep;
