import { createContext, ReactNode, useEffect, useState } from 'react';
import { Content } from '../shared/interfaces/screeningContent.interface';
import { defaultScreeningContent } from '../utils/defaultContent';
import { axiosInstance } from '../utils/axios';
import parse from 'html-react-parser';
import {
  DashboardPageContent,
  ParsedDashboardPageContent,
} from '../shared/interfaces/dashboardContent.interface';

export const DashboardPageContentContext = createContext<
  ParsedDashboardPageContent | 'loading'
>('loading');

const fetchContent = async (): Promise<DashboardPageContent> => {
  return axiosInstance
    .get('content/dashboard/NL')
    .then((result) => {
      return result.data;
    })
    .catch(() => defaultScreeningContent);
};

const parseContent = (
  content: DashboardPageContent,
): ParsedDashboardPageContent => {
  const parsedContent: Partial<ParsedDashboardPageContent> = {}; // Use Partial to allow gradual assignment

  Object.keys(content).forEach((step) => {
    const stepKey = step as keyof DashboardPageContent;
    parsedContent[stepKey] = {} as any; // Ensure type safety

    Object.keys(content[stepKey]).forEach((key) => {
      const keyName = key as keyof (typeof content)[typeof stepKey];
      const contentItem = content[stepKey][keyName] as Content;

      if (
        contentItem?.type === 'text' &&
        !unparsedTextFields.find((item) => item === keyName)
      ) {
        parsedContent[stepKey]![keyName] = parse(contentItem.text);
      } else {
        parsedContent[stepKey]![keyName] = contentItem?.text ?? '';
      }
    });
  });

  return parsedContent as ParsedDashboardPageContent;
};

// Fields that are text but shouldn't be parsed because they need to be strings where they are used
const unparsedTextFields: string[] = [
  'idCheckInfo',
  'backVerificationInfo',
  'incomeCheckInfo',
  'kvkCheckInfo',
  'bkrCheckInfo',
  'kadasterCheckInfo',
  'referenceCheckInfo',
  'eductionCheckInfo',
  'cocCheckInfo',
  'deleteUserTitle',
  'deleteUserDiscription',
  'usersTutorialTitle',
  'usersTutorialDescription',
  'addUserTitle',
  'fullPageTutorialTitle',
  'fullPageTutorialDescription',
  'nameFieldTutorialTitle',
  'nameFieldTutorialDescription',
  'emailFieldTutorialTitle',
  'emailFieldTutorialDescription',
  'userTypeFieldTutorialTitle',
  'userTypeFieldTutorialDescription',
  'confirmationTutorialTitle',
  'confirmationTutorialDescription',
];

export const DashboardPageContentProvider = (props: {
  children: ReactNode;
}) => {
  const [content, setContent] = useState<
    ParsedDashboardPageContent | 'loading'
  >('loading');

  useEffect(() => {
    fetchContent().then((dashboardPageContent) =>
      setContent(parseContent(dashboardPageContent)),
    );
  }, []);

  return (
    <DashboardPageContentContext.Provider value={content}>
      {props.children}
    </DashboardPageContentContext.Provider>
  );
};

export const removeP = (text: string): string => {
  return text.replaceAll('<p>', '').replaceAll('</p>', '');
};
