import { useTitle } from '../../../hooks/useTitle';
import DashboardLayout from '../../../components/layout/dashboard';
import { ShoppingCartOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Flex, notification } from 'antd';
import '../../../styles/credits-store.css';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { Credits } from '../../../shared/enums/credits.enum';
import { axiosInstance } from '../../../utils/axios';
import { useContext, useState } from 'react';
import LoadingSpinner from '../../../components/screening-steps/util/LoadingSpinner';
import { DashboardPageContentContext } from '../../../context/DashboardContentProvider';

export const CreditStore = () => {
  useTitle('Credits Kopen');
  const [messageApi, contextHolder] = notification.useNotification();

  const content = useContext(DashboardPageContentContext);

  const createCreditPayment = (credits: Credits) => async () => {
    await axiosInstance
      .post('/credit-payment', { credits })
      .then((response) => {
        const url = response.data;
        window.open(url, '_blank')?.focus();
      })
      .catch(() => {
        messageApi.error({
          message:
            'Er is iets misgegaan bij het maken van het betalingsverzoek',
          placement: 'bottomRight',
        });
      });
  };

  return content === 'loading' ? (
    <LoadingSpinner />
  ) : (
    <DashboardLayout>
      {contextHolder}
      <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <h1 style={{ textAlign: 'center', fontSize: '32px' }}>
          {content.credits.title}
        </h1>
        <div style={{ textAlign: 'center' }}>{content.credits.description}</div>
        <Flex
          gap="40px 10px"
          style={{ marginTop: '60px' }}
          justify="center"
          wrap="wrap"
        >
          <CreditCard
            credits={10}
            price={250}
            onPurchase={createCreditPayment(Credits.TEN)}
          />
          <CreditCard
            credits={20}
            price={475}
            discount={5}
            onPurchase={createCreditPayment(Credits.TWENTY)}
            mostChosen
          />
          <CreditCard
            credits={50}
            price={1162.5}
            discount={7}
            onPurchase={createCreditPayment(Credits.FIFTY)}
          />
        </Flex>
      </div>
    </DashboardLayout>
  );
};

const CreditCard = (props: {
  credits: number;
  price: number;
  discount?: number; // in percentages
  mostChosen?: boolean;
  onPurchase: () => Promise<void>;
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [loading, setLoading] = useState(false);

  const handlePurchase = async () => {
    setLoading(true);
    await props.onPurchase().finally(() => setLoading(false));
  };

  return (
    <Flex vertical className="credit-card">
      <div
        style={{
          display: !isMobile || props.mostChosen ? 'block' : 'none', // on mobile, the tiles are shown in a row, so a display set to none looks better
          visibility: props.mostChosen ? 'visible' : 'hidden',
        }}
        className="most-chosen"
      >
        Meest gekozen
      </div>
      <Card
        style={{
          width: '25rem',
          padding: '0 10px 0 10px',
        }}
        title={<h1>{props.credits} credits</h1>}
      >
        <Flex vertical style={{ fontSize: '20px' }}>
          <i>
            Prijs: €{props.price.toFixed(2)}
            <small> (incl btw: €{(props.price * 1.21).toFixed(2)})</small>
          </i>
          <div style={{ fontSize: '15px' }}>
            Prijs per credit: €{(props.price / props.credits).toFixed(2)}{' '}
            <i
              style={{
                visibility: props.discount ? 'visible' : 'hidden',
                color: 'var(--light-grey)',
              }}
            >
              ({props.discount + '% korting'})
            </i>
          </div>
          <div style={{ textAlign: 'right', marginTop: '1rem' }}>
            <Button
              type="primary"
              icon={loading ? <LoadingOutlined /> : <ShoppingCartOutlined />}
              onClick={handlePurchase}
              disabled={loading}
            >
              Kopen
            </Button>
          </div>
        </Flex>
      </Card>
    </Flex>
  );
};
