import { useContext, useEffect, useState } from 'react';
import { disableDashboardTour } from '../../utils/dashboardTourFunctions';
import { TourProps } from 'antd';
import { useMediaQuery } from '../useMediaQuery';
import { DashboardPageContentContext } from '../../context/DashboardContentProvider';

export function useScreeningDetailsTour() {
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>();
  const [tourOpen, setTourOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const content = useContext(DashboardPageContentContext);

  useEffect(() => {
    if (content === 'loading') return;
    setTourSteps([
      {
        title: content.screeningDetails.screeningDetailsTutorialTitle,
        description:
          content.screeningDetails.screeningDetailsTutorialDescription,
        target: null,
      },
    ]);

    if (localStorage.getItem('tour-dashboard-screening-details') && !isMobile) {
      setTourOpen(true);
      disableDashboardTour('tour-dashboard-screening-details');
    }
  }, [isMobile, content]);

  return { tourSteps, setTourOpen, tourOpen };
}
