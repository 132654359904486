import { useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axios';
import {
  Button,
  Flex,
  Input,
  notification,
  Popconfirm,
  Row,
  Table,
} from 'antd';
import { SaveTwoTone } from '@ant-design/icons';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import LoadingSpinner from '../screening-steps/util/LoadingSpinner';
import { ContentFilterButton } from './ContentFilterButton';
import Column from 'antd/es/table/Column';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  ContentSteps,
  ConfigurationScreens,
  ContentLanguage,
  languagesPerScreen,
} from '../../shared/interfaces/screeningContent.interface';
import { DashboardSteps } from '../../shared/interfaces/dashboardContent.interface';
import '../../styles/dashboard.css';
import { ContentLanguageButton } from './ContentLanguageButton';

interface Content {
  step: string;
  field: string;
  language: string;
  text: string;
  type: string;
}

interface EditableContent {
  content: Content;
  field: string;
  editorState: EditorState;
  key: string;
}

const ContentTable = ({ screen }: { screen: ConfigurationScreens }) => {
  const [content, setContent] = useState<Content[]>();
  const [step, setStep] = useState<ContentSteps | DashboardSteps>(
    ContentSteps.PERSONADETAILS,
  );
  const [contentStep, setContentStep] = useState<EditableContent[]>();
  const [loadingData, setLoadingData] = useState(true);
  const [messageApi, contextHolder] = notification.useNotification();
  const [getEndpoint, setGetEndpoint] = useState('content/screening');
  const [language, setLanguage] = useState(ContentLanguage.NL);

  useEffect(() => {
    const languages = languagesPerScreen(screen);
    if (languages.length === 1) {
      setLanguage(languages[0]);
    }
    switch (screen) {
      case ConfigurationScreens.DASHBOARD:
        setGetEndpoint('content/dashboard');
        setStep(DashboardSteps.SCREENINGS);
        break;
      case ConfigurationScreens.SCREENINGS:
        setGetEndpoint('content/screening');
        setStep(ContentSteps.PERSONADETAILS);
        break;
    }
  }, [screen]);

  useEffect(() => {
    axiosInstance
      .get(`${getEndpoint}/raw/${language}`)
      .then((res) => {
        let contentData = res.data;
        if (screen === ConfigurationScreens.DASHBOARD) {
          contentData = contentData.map((c: any) => {
            return {
              ...c,
              step: c.page,
            };
          });
        }
        contentData = contentData as Content[];
        setContent(contentData);

        // This is duplicate code, but React doesn't like it when I make this a function.
        const step =
          screen === ConfigurationScreens.SCREENINGS
            ? ContentSteps.PERSONADETAILS
            : DashboardSteps.SCREENINGS;
        const contentStepData = contentData.filter(
          (singleContent: { step: ContentSteps | DashboardSteps }) =>
            singleContent.step === step,
        );

        setContentStep(
          contentStepData?.map(
            (singleContent: { text: string; field: string }) => {
              return {
                content: singleContent,
                editorState: editorStateFromHtml(singleContent.text),
                key: singleContent.text,
                field: singleContent.field,
              };
            },
          ),
        );
      })
      .finally(() => setLoadingData(false));
  }, [getEndpoint, screen, language]);

  function editorStateFromHtml(html: string): EditorState {
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    return EditorState.createWithContent(contentState);
  }

  function hasContentChanged(contentField: EditableContent): boolean {
    const currentContent = contentStep?.find(
      (singleContent: EditableContent): boolean =>
        contentField.content.field === singleContent.content.field &&
        contentField.content.step === singleContent.content.step &&
        contentField.content.language === singleContent.content.language,
    );
    const oldContent = content?.find(
      (singleContent: Content): boolean =>
        contentField.content.field === singleContent.field &&
        contentField.content.step === singleContent.step &&
        contentField.content.language === singleContent.language,
    );

    if (currentContent && oldContent) {
      return (
        stateToHTML(currentContent.editorState.getCurrentContent()) !==
          oldContent.text ||
        (currentContent.content.type === 'link' &&
          currentContent.content.text !== oldContent.text)
      );
    }

    return false;
  }

  function setStringifiedContentStep(currentStep: ContentSteps) {
    const contentData = content?.filter(
      (singleContent) => singleContent.step === currentStep,
    );

    setContentStep(
      contentData?.map((singleContent) => {
        return {
          content: singleContent,
          editorState: editorStateFromHtml(singleContent.text),
          key: singleContent.text,
          field: singleContent.field,
        };
      }),
    );
  }

  function setCurrentStep(newStep: ContentSteps) {
    setStep(newStep);
    setStringifiedContentStep(newStep);
  }

  function saveSingleContent(contentField: EditableContent) {
    const updated = contentStep?.find(
      (c) =>
        c.content.field === contentField.content.field &&
        c.content.language === contentField.content.language &&
        c.content.step === contentField.content.step,
    );

    if (updated) {
      if (contentField.content.type === 'text')
        contentField.content.text = stateToHTML(
          contentField.editorState.getCurrentContent(),
        );
      // update reference to hide the save icon
      else contentField.content.text = updated.content.text;

      updated.content.text = contentField.content.text;

      let sendContent = updated.content as any;
      if (screen === ConfigurationScreens.DASHBOARD) {
        sendContent = {
          ...sendContent,
          page: sendContent.step,
        };
      }

      axiosInstance
        .post(`/${getEndpoint}`, { updated: sendContent })
        .catch(() => {
          messageApi.error({
            message: 'Er is iets misgegaan.',
            placement: 'bottomRight',
          });
        })
        .finally(() => {
          const newContent = content?.map((singleContentStep) =>
            singleContentStep.field === contentField.content.field &&
            singleContentStep.step === contentField.content.step &&
            singleContentStep.language === contentField.content.language
              ? updated.content
              : singleContentStep,
          );

          if (newContent) {
            setContent(newContent);
          }

          (updated as any).key = updated.content.text; // to prevent React error about keys
          messageApi.success({
            message: 'De tekst is successvol opgeslagen.',
            placement: 'bottomRight',
          });
        });
    }
  }

  function changeSingleContent(
    content: EditableContent,
    editorState: EditorState,
  ) {
    const newContent = {
      ...content,
      editorState,
    };

    const oldContent = contentStep?.map((singleContent) =>
      singleContent.content.field === content.content.field &&
      singleContent.content.step === content.content.step
        ? newContent
        : singleContent,
    );

    setContentStep(oldContent);
  }

  function changeSingleLink(content: EditableContent, link: string) {
    const newContent = {
      ...content.content,
      text: link,
    };
    const newEditableContent = {
      ...content,
      content: newContent,
    };

    const oldContent = contentStep?.map((singleContent) =>
      singleContent.content.field === content.content.field &&
      singleContent.content.step === content.content.step
        ? newEditableContent
        : singleContent,
    );
    setContentStep(oldContent);
  }

  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <>
      {contextHolder}
      <Flex className="content-table" vertical>
        <Row justify="end" style={{ marginBottom: '1rem', marginTop: '1rem' }}>
          <ContentFilterButton
            content={step}
            setContent={setCurrentStep}
            currentScreen={screen}
          />
          {languagesPerScreen(screen).length > 1 && (
            <ContentLanguageButton
              language={language}
              setLanguage={setLanguage}
            />
          )}
        </Row>
        <Table
          scroll={{ x: true }}
          dataSource={contentStep}
          bordered
          style={{ overflow: 'visible' }}
        >
          <Column
            title="Veld"
            dataIndex="field"
            key="field"
            sorter={(a: any, b: any) => a.field.localeCompare(b.field)}
            width="30%"
          />
          <Column
            title="Tekst/Link"
            dataIndex="text"
            key="text"
            sorter={(a: any, b: any) => a.field.localeCompare(b.field)}
            width="70%"
            render={(text: string, record: EditableContent) => {
              return (
                <span
                  key={
                    record.content.field +
                    record.content.step +
                    record.content.language
                  }
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  {record.content.type === 'text' ? (
                    <Editor
                      editorState={record.editorState}
                      onEditorStateChange={(state) =>
                        changeSingleContent(record, state)
                      }
                      toolbar={{
                        options: ['inline', 'list', 'link'],
                      }}
                      editorStyle={{
                        paddingLeft: '10px',
                        overflow: 'hidden',
                      }}
                      wrapperStyle={{
                        width: '100%',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                      }}
                    />
                  ) : (
                    <Input
                      defaultValue={record.content.text}
                      onChange={(val) =>
                        changeSingleLink(record, val.target.value)
                      }
                    ></Input>
                  )}
                  <Popconfirm
                    title="Tekst opslaan?"
                    okText="Ja"
                    cancelText="Nee"
                    onConfirm={() => saveSingleContent(record)}
                  >
                    <Button
                      type="text"
                      style={{
                        display: 'inline-block',
                        visibility: hasContentChanged(record)
                          ? 'visible'
                          : 'hidden',
                      }}
                    >
                      <SaveTwoTone />
                    </Button>
                  </Popconfirm>
                </span>
              );
            }}
          />
        </Table>
      </Flex>
    </>
  );
};

export default ContentTable;
