import { Navigate } from 'react-router';
import { AuthService } from '../../redux/services/auth.service';
import UserRole from '../../shared/enums/user-role.enum';

export const AuthenticatedRoute = ({
  children,
  superAdminRoute,
  role,
}: {
  children: JSX.Element;
  superAdminRoute?: boolean;
  role?: UserRole;
}) => {
  const user = AuthService.getCurrentUser();
  if (superAdminRoute && !user.isSuperAdmin) {
    return <Navigate to="/login" />;
  }
  if (role && user?.role !== role) {
    return <Navigate to="/login" />;
  }
  if (!user) return <Navigate to="/login" />;

  return children;
};
