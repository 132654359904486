import { JSX } from 'react';

export interface GenericScreeningContent<T> {
  personaDetails: PersonaDetails<T>;
  identification: Identification<T>;
  bankDetails: BankDetails<T>;
  incomeCheck: IncomeCheck<T>;
  kvkCheck: KvkCheck<T>;
  bkrCheck: BkrCheck<T>;
  kadasterCheck: KadasterCheck<T>;
  ownWorkStatement: OwnWorkStatement<T>;
  workReferenceStatement: WorkReferenceStatement<T>;
  ownRentStatement: OwnRentStatement<T>;
  ownRentStatementRental: OwnRentStatementRental<T>;
  landlordStatementCheck: LandlordStatementCheck<T>;
  certificateOfConductCheck: CertificateOfConductCheck<T>;
  educationCheck: EducationCheck<T>;
  confirmation: Confirmation<T>;
}

export enum ContentSteps {
  PERSONADETAILS = 'personaDetails',
  IDENTIFICATION = 'identification',
  BANKDETAILS = 'bankDetails',
  INCOMECHECK = 'incomeCheck',
  KVKCHECK = 'kvkCheck',
  BKRCHECK = 'bkrCheck',
  KADASTERCHECK = 'kadasterCheck',
  OWNWORKSTATEMENT = 'ownWorkStatement',
  WORKREFERENCESTATEMENT = 'workReferenceStatement',
  OWNRENTSTATEMENT = 'ownRentStatement',
  OWNRENTSTATEMENTRENTAL = 'ownRentStatementRental',
  LANDLORDSTATEMENT = 'landlordStatementCheck',
  EDUCATIONCHECK = 'educationCheck',
  COCCHECK = 'certificateOfConductCheck',
  CONFIRMATION = 'confirmation',
}

export enum ConfigurationScreens {
  DASHBOARD = 'dashboard',
  SCREENINGS = 'screenings',
}

interface Step<T> {
  title: T;
  description?: T;
}

interface PersonaDetails<T> extends Step<T> {}

interface Identification<T> extends Step<T> {
  dataCheckerRequestDetails: T;
}

interface BankDetails<T> extends Step<T> {}

interface IncomeCheck<T> extends Step<T> {
  requirementTitle: T;
  requirementPrivateOne: T;
  requirementPrivateTwo: T;
  requirementPrivateThree: T;
  requirementPrivateOneInfo: T;
  requirementPrivateTwoInfo: T;
  requirementPrivateThreeInfo: T;
  requirementBusiness: T;
  requirementBusinessInfo: T;
  taxLink: T;
  cocLink: T;
}

interface KvkCheck<T> extends Step<T> {
  kvkNumberQuestion: T;
}

interface BkrCheck<T> extends Step<T> {
  bkrLink: T;
  bkrRequest: T;
}

interface KadasterCheck<T> extends Step<T> {
  kadasterQuestion: T;
}

export interface OwnWorkStatement<T> extends Step<T> {
  dispute: T;
  fired: T;
  firedIntegrity: T;
  integrityTest: T;
  conviction: T;
  requestIntegrity: T;
}

interface WorkReferenceStatement<T> extends Step<T> {
  permission: T;
}

export interface OwnRentStatement<T> extends Step<T> {
  previousRental: T;
  previousDenial: T;
  ownership: T;
  conviction: T;
}

export interface OwnRentStatementRental<T> extends Step<T> {
  conflictLandlord: T;
  conflictTenant: T;
  backlog: T;
}

interface LandlordStatementCheck<T> extends Step<T> {
  permission: T;
}

interface EducationCheck<T> extends Step<T> {
  duoLink: T;
}

interface CertificateOfConductCheck<T> extends Step<T> {}

interface Confirmation<T> extends Step<T> {
  agreeText: T;
  truthStatement: T;
}

export type Content = {
  text: string;
  type: 'text' | 'link';
};

export enum ContentLanguage {
  NL = 'NL',
  EN = 'EN',
}

export function languagesPerScreen(
  screen: ConfigurationScreens,
): ContentLanguage[] {
  switch (screen) {
    case ConfigurationScreens.DASHBOARD:
      return [ContentLanguage.NL];
    case ConfigurationScreens.SCREENINGS:
      return [ContentLanguage.NL, ContentLanguage.EN];
  }
}

export type ScreeningContent = GenericScreeningContent<Content>;
export type parsedContent = string | JSX.Element | JSX.Element[];
export type ParsedScreeningContent = GenericScreeningContent<parsedContent>;
