import { useContext, useEffect, useState } from 'react';
import { disableDashboardTour } from '../../utils/dashboardTourFunctions';
import { TourProps } from 'antd';
import { useMediaQuery } from '../useMediaQuery';
import {
  DashboardPageContentContext,
  removeP,
} from '../../context/DashboardContentProvider';

export function useDashBoardAddUserTour(
  getRef: (key: string) => React.RefObject<HTMLElement>,
) {
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>();
  const [tourOpen, setTourOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const content = useContext(DashboardPageContentContext);

  useEffect(() => {
    if (localStorage.getItem('tour-dashboard-add-user') && !isMobile) {
      setTourOpen(true);
      disableDashboardTour('tour-dashboard-add-user');
    }
    if (content === 'loading') return;
    setTourSteps([
      {
        title: removeP(content.addUser.fullPageTutorialTitle as string),
        description: removeP(
          content.addUser.fullPageTutorialDescription as string,
        ),
        target: getRef('card')?.current,
      },
      {
        title: removeP(content.addUser.nameFieldTutorialTitle as string),
        description: removeP(
          content.addUser.nameFieldTutorialDescription as string,
        ),
        target: getRef('name')?.current,
      },
      {
        title: removeP(content.addUser.emailFieldTutorialTitle as string),
        description: removeP(
          content.addUser.emailFieldTutorialDescription as string,
        ),
        target: getRef('email')?.current,
      },
      {
        title: removeP(content.addUser.userTypeFieldTutorialTitle as string),
        description: removeP(
          content.addUser.userTypeFieldTutorialDescription as string,
        ),
        target: getRef('role')?.current,
      },
      {
        title: removeP(content.addUser.confirmationTutorialTitle as string),
        description: removeP(
          content.addUser.confirmationTutorialDescription as string,
        ),
        target: getRef('submit')?.current,
      },
    ]);
  }, [isMobile, getRef, content]);

  return { tourSteps, setTourOpen, tourOpen };
}
