import {
  Button,
  Divider,
  Form,
  Switch,
  Typography,
  Tooltip,
  theme,
  Select,
} from 'antd';
import { Dispatch, SetStateAction, useContext } from 'react';
import { SelectSteps } from '../../pages/dashboard/start-screening/start-screening';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SelectPreset } from './SelectPreset';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import {
  DashboardPageContentContext,
  removeP,
} from '../../context/DashboardContentProvider';
import { ParsedDashboardPageContent } from '../../shared/interfaces/dashboardContent.interface';

const { Title } = Typography;
const { useToken } = theme;

interface SelectableSteps extends SelectSteps {
  referent: boolean;
}

interface SelectFormItems {
  name: string;
  key: keyof SelectableSteps;
  description: string;
}

const formItems = (
  content: ParsedDashboardPageContent | 'loading',
): SelectFormItems[] => {
  if (content === 'loading') return [];
  return [
    {
      name: 'ID Check',
      key: 'identification',
      description: removeP(content.startScreening.idCheckInfo as string),
    },
    {
      name: 'Bank verificatie',
      key: 'bankData',
      description: removeP(
        content.startScreening.backVerificationInfo as string,
      ),
    },
    {
      name: 'Inkomenscheck',
      key: 'incomeCheck',
      description: removeP(content.startScreening.incomeCheckInfo as string),
    },
    {
      name: 'KVK Check',
      key: 'kvk',
      description: removeP(content.startScreening.kvkCheckInfo as string),
    },
    {
      name: 'BKR Check',
      key: 'bkr',
      description: removeP(content.startScreening.bkrCheckInfo as string),
    },
    {
      name: 'Kadaster',
      key: 'kadaster',
      description: removeP(content.startScreening.kadasterCheckInfo as string),
    },
    {
      name: 'Referentiecheck',
      key: 'referent',
      description: removeP(content.startScreening.referenceCheckInfo as string),
    },
    {
      name: 'Opleidingscheck',
      key: 'education',
      description: removeP(content.startScreening.eductionCheckInfo as string),
    },
    {
      name: 'Verklaring Omtrent Gedrag',
      key: 'certificateOfConduct',
      description: removeP(content.startScreening.cocCheckInfo as string),
    },
  ];
};

function SelectScreeningSteps({
  selectSteps,
  setSelectSteps,
  allSelected,
  changeAllSelected,
}: {
  selectSteps: SelectSteps;
  setSelectSteps: Dispatch<SetStateAction<SelectSteps>>;
  allSelected: boolean;
  changeAllSelected: () => void;
}) {
  const { token } = useToken();
  const isMobile = useMediaQuery('(max-width: 480px)');

  const content = useContext(DashboardPageContentContext);

  function handleReferentType(value: string) {
    if (value === 'employeeIntegrity') {
      setSelectSteps((prev) => ({
        ...prev,
        referent: true,
        landlordStatement: false,
        employeeIntegrity: true,
      }));
    }
    if (value === 'landlordStatement') {
      setSelectSteps((prev) => ({
        ...prev,
        referent: true,
        landlordStatement: true,
        employeeIntegrity: false,
      }));
    }
  }

  const referentType = () => {
    if (selectSteps.employeeIntegrity) return 'employeeIntegrity';
    if (selectSteps.landlordStatement) return 'landlordStatement';
    return null;
  };

  return (
    <>
      <Title level={5} style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>
        Selecteer Checks
      </Title>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <div
          style={{
            display: isMobile ? 'block' : 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <SelectPreset setSelectSteps={setSelectSteps} />

          <Button
            type="link"
            onClick={changeAllSelected}
            style={{ padding: 0, margin: 0, display: 'block' }}
          >
            {allSelected ? 'Deselecteer' : 'Selecteer'} alle checks
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      {formItems(content).map((item) => (
        <>
          {item.key === 'referent' ? (
            <Form.Item noStyle name={item.key}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  {item.name}
                  <Tooltip
                    title={
                      <div>
                        <p>{item.description}</p>
                      </div>
                    }
                    overlayStyle={{ maxWidth: 'fit-content(<60>)' }}
                    color={token.colorPrimary}
                  >
                    <InfoCircleOutlined style={{ marginLeft: '1%' }} />
                  </Tooltip>
                </div>
                <Switch
                  size="small"
                  checked={selectSteps[item.key]}
                  onChange={(value) => {
                    setSelectSteps((prev) => ({
                      ...prev,
                      [item.key]: value,
                    }));
                    if (!value)
                      setSelectSteps((prev) => ({
                        ...prev,
                        landlordStatement: false,
                        employeeIntegrity: false,
                      }));
                  }}
                />
              </div>
              {selectSteps.referent && (
                <Form.Item
                  required
                  label="Type van referentiecheck"
                  style={{ marginLeft: 40, marginTop: 8 }}
                >
                  <Select
                    style={{ width: '25%' }}
                    onChange={(v) => handleReferentType(v)}
                    value={referentType()}
                  >
                    <Select.Option value="employeeIntegrity">
                      Werknemers
                    </Select.Option>
                    <Select.Option value="landlordStatement">
                      Huurders
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            </Form.Item>
          ) : (
            <Form.Item noStyle name={item.key}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  {item.name}
                  <Tooltip
                    title={
                      <div>
                        <p>{item.description}</p>
                      </div>
                    }
                    overlayStyle={{ maxWidth: 'fit-content(<60>)' }}
                    color={token.colorPrimary}
                  >
                    <InfoCircleOutlined style={{ marginLeft: '1%' }} />
                  </Tooltip>
                </div>

                <Switch
                  size="small"
                  checked={selectSteps[item.key]}
                  onChange={(value) =>
                    setSelectSteps((prev) => ({
                      ...prev,
                      [item.key]: value,
                    }))
                  }
                />
              </div>
              <Divider style={{ marginTop: 8, marginBottom: 8 }} />
            </Form.Item>
          )}
        </>
      ))}
    </>
  );
}

export default SelectScreeningSteps;
