import { DashboardPageContent } from '../shared/interfaces/dashboardContent.interface';
import { ScreeningContent } from '../shared/interfaces/screeningContent.interface';

// If the structure of ScreeningContent changes, call the content endpoint and copy-paste the response.
export const defaultScreeningContent: ScreeningContent = {
  personaDetails: {
    title: {
      text: '<p>Persoonlijke gegevens</p>',
      type: 'text',
    },
  },
  identification: {
    title: {
      text: '<p>Legitimatie Check</p>',
      type: 'text',
    },
    description: {
      text: '<p>Voor de screening is een ID-check vereist. U wordt verzocht een foto van een geldig identiteitsbewijs te maken. Het document wordt automatisch gecontroleerd op echtheid en geldigheid. Na het uploaden van het document wordt er gevraagd om een selfie te maken via de webcam of met uw telefoon. Volg hierbij de aanwijzingen tijdens dit proces. Na het maken van de selfie vindt er een identificatieproces plaats. Deze identiteitscheck kan 5 tot 10 minuten duren. U kunt ondertussen doorgaan met de andere checks.</p>',
      type: 'text',
    },
    dataCheckerRequestDetails: {
      text: '<p>Stuur een verzoek naar de DataChecker</p>',
      type: 'text',
    },
  },
  bankDetails: {
    title: {
      text: '<p>Bankverificatie</p>',
      type: 'text',
    },
    description: {
      text: '<p>Via onderstaande link kunt u een betaling van 1 eurocent doen. Wij verzoeken u deze betaling te doen via de bankrekening waarop ook uw salaris zal worden gestort/waarvan de huur zal worden afgeschreven. Met deze betaling kan uw bank uw identiteit voor u bevestigen.</p>',
      type: 'text',
    },
  },
  incomeCheck: {
    title: {
      text: '<p>InkomensVerklaring</p>',
      type: 'text',
    },
    requirementTitle: {
      text: '<p>Voor de screening wordt een verklaring omtrent uw inkomen gevraagd, hiervoor is vereist:</p>',
      type: 'text',
    },
    requirementPrivateOne: {
      text: '<p>Uw laatste 3 loonstroken</p>',
      type: 'text',
    },
    requirementPrivateTwo: {
      text: '<p>Uw laatste IB60</p>',
      type: 'text',
    },
    requirementPrivateThree: {
      text: '<p>Uw laatste jaaropgave</p>',
      type: 'text',
    },
    requirementPrivateOneInfo: {
      text: '<p>Loonstroken downloaden doet u zo:  </p>\n<ul>\n  <li>Log in op het portaal van je werkgever  </li>\n  <li>Zoek de pagina met je loonstroken.  </li>\n  <li>Klik op de knop "Download" om je laatste 3 loonstroken te downloaden.</li>\n</ul>',
      type: 'text',
    },
    requirementPrivateTwoInfo: {
      text: "<p>Een IB60 downloaden doet u zo:  </p>\n<ul>\n  <li>Log in op Mijn Belastingdienst.  </li>\n  <li>Klik op 'Mijn gegevens'.  </li>\n  <li>Klik op 'Inkomensverklaring opvragen en geregistreerd inkomen bekijken'.  </li>\n  <li>Klik vervolgens op 'Inkomensverklaring opvragen' bij het meest recente jaar.  </li>\n  <li>Klik op 'Inkomensverklaring opvragen'.</li>\n</ul>",
      type: 'text',
    },
    requirementPrivateThreeInfo: {
      text: '<p>Een jaaropgave downloaden doet u zo:  </p>\n<ul>\n  <li>Log in op het portaal van je werkgever  </li>\n  <li>Zoek de pagina met je jaaropgave.  </li>\n  <li>Klik op de knop "Download" om de jaaropgave te downloaden.</li>\n</ul>',
      type: 'text',
    },
    requirementBusiness: {
      text: '<p>Uw laatste 3 jaarcijfers</p>',
      type: 'text',
    },
    requirementBusinessInfo: {
      text: '<p>Jaarcijfers downloaden doet u zo:  </p>\n<ul>\n  <li>Log in op de website van de Kamer van Koophandel.  </li>\n  <li>Ga naar de pagina met je jaarcijfers.  </li>\n  <li>Klik op de knop "Download" om de jaarcijfers te downloaden.</li>\n</ul>',
      type: 'text',
    },
    taxLink: {
      text: 'https://mijn.belastingdienst.nl/mbd-pmb/Inkomensverklaring',
      type: 'link',
    },
    cocLink: {
      text: 'https://www.kvk.nl/inloggen/',
      type: 'link',
    },
  },
  kvkCheck: {
    title: {
      text: '<p>KVK Check</p>',
      type: 'text',
    },
    kvkNumberQuestion: {
      text: '<p>Bent u eigenaar van een geregistreerd bedrijf met een bijhorend KvK-nummer?</p>',
      type: 'text',
    },
  },
  bkrCheck: {
    title: {
      text: '<p>BKR Registratie</p>',
      type: 'text',
    },
    description: {
      text: '<p>Lees voordat u verder gaat eerst de volledige uitleg voor het downloaden van de BKR. </p>\n<ul>\n  <li>Open BKR en kies hier uw bank. </li>\n  <li>Nadat u uw gegevens heeft opgegeven komt u op een overzichtspagina voor uw BKR registraties. </li>\n  <li>Deze pagina heeft als titel "Welkom bij je kredietgegevens". </li>\n  <li>Druk dan op CTRL + P voor Windows en ⌘ + P voor Mac OS.</li>\n  <li>Sla het document op als pdf en daarna kunt u hieronder de pdf uploaden.</li>\n</ul>',
      type: 'text',
    },
    bkrLink: {
      text: 'https://mijnkredietoverzicht.bkr.nl/inloggen',
      type: 'link',
    },
    bkrRequest: {
      text: '<p>BKR registraties PDF</p>',
      type: 'text',
    },
  },
  kadasterCheck: {
    title: {
      text: '<p>Kadaster gegevens</p>',
      type: 'text',
    },
    kadasterQuestion: {
      text: '<p>Ben je eigenaar van een object welke is geregistreerd bij het Kadaster?</p>',
      type: 'text',
    },
  },
  ownWorkStatement: {
    title: {
      text: '<p>Eigen verklaring</p>',
      type: 'text',
    },
    description: {
      text: '<p>Deze vragen worden gesteld om inzicht te krijgen in de integriteit van u als werknemer.</p>',
      type: 'text',
    },
    dispute: {
      text: '<p>Heeft u ooit een (juridisch) geschil met een werkgever gehad?</p>',
      type: 'text',
    },
    fired: {
      text: '<p>Bent u de afgelopen 5 jaar ontslagen?</p>',
      type: 'text',
    },
    firedIntegrity: {
      text: '<p>Is er in de afgelopen 5 jaar op basis van uw integriteit sprake geweest van ontslag via een zogenoemde vaststellingsovereenkomst?</p>',
      type: 'text',
    },
    integrityTest: {
      text: '<p>Bent u in het verleden getoetst op integriteit of vakbekwaamheid?</p>',
      type: 'text',
    },
    conviction: {
      text: '<p>Bent u ooit strafrechtelijk vervolgd voor een door u gepleegd strafbaar feit gedurende werktijd?</p>',
      type: 'text',
    },
    requestIntegrity: {
      text: '<p>Zou er indien gewenst een integriteitsverklaring kunnen worden opgevraagd bij uw voormalig(e) werkgever(s)?</p>',
      type: 'text',
    },
  },
  workReferenceStatement: {
    title: {
      text: '<p>Referentie verklaring</p>',
      type: 'text',
    },
    description: {
      text: '<p>Wij vragen u hieronder de gegevens van uw (voormalige) werkgever in te vullen. Indien u meerdere werkgevers heeft kunt u deze met de knop onderaan toevoegen.</p>',
      type: 'text',
    },
    permission: {
      text: '<p>Ik geef toestemming om contact op te nemen met de opgegeven referent(en).</p>',
      type: 'text',
    },
  },
  ownRentStatement: {
    title: {
      text: '<p>Eigen verklaring - Algemeen</p>',
      type: 'text',
    },
    description: {
      text: '<p>Voor het huren van een object is een eigen verklaring vereist. Deze vragenlijst is bedoeld om een beeld te krijgen van de huurder, de vragen hieronder zijn van toepassing op iedereen. De andere twee stappen zijn alleen van toepassing op personen die een woning of bedrijfsruimte gehuurd hebben.</p>',
      type: 'text',
    },
    previousRental: {
      text: '<p>Heeft u eerder een woning of bedrijfsruimte gehuurd?</p>',
      type: 'text',
    },
    previousDenial: {
      text: '<p>Is u ooit een huurwoning of bedrijfsruimte geweigerd?</p>',
      type: 'text',
    },
    ownership: {
      text: '<p>Heeft u onroerend goed op uw naam?</p>',
      type: 'text',
    },
    conviction: {
      text: '<p>Bent u de afgelopen 5 jaar veroordeeld voor drugsgerelateerde zaken?</p>',
      type: 'text',
    },
  },
  ownRentStatementRental: {
    title: {
      text: '<p>Eigen verklaring - Huurwoning</p>',
      type: 'text',
    },
    description: {
      text: '<p>Deze extra vragen zijn voor personen die een woning of bedrijfsruimte gehuurd hebben.</p>',
      type: 'text',
    },
    conflictLandlord: {
      text: '<p>Heeft u ooit een geschil met een verhuurder gehad?</p>',
      type: 'text',
    },
    conflictTenant: {
      text: '<p>Heeft u een geschil met mede-huurders gehad?</p>',
      type: 'text',
    },
    backlog: {
      text: '<p>Heeft u ooit een huurachterstand gehad?</p>',
      type: 'text',
    },
  },
  landlordStatementCheck: {
    title: {
      text: '<p>Verhuurdersverklaring</p>',
      type: 'text',
    },
    description: {
      text: '<p>Wij vragen u hieronder de gegevens van uw (voormalige) verhuurder in te vullen. Indien u meerdere referenten heeft kunt u deze met de knop onderaan toevoegen.</p>',
      type: 'text',
    },
    permission: {
      text: '<p>Ik geef toestemming om contact op te nemen met de opgegeven referent(en).</p>',
      type: 'text',
    },
  },
  educationCheck: {
    title: {
      text: '<p>Opleiding</p>',
      type: 'text',
    },
    description: {
      text: "<p>Hieronder kunt u uw opleidingen opgeven, ook kunt u hierbij een foto/bestand van uw diploma uploaden.  Met de knop hieronder gaat u rechtstreeks naar de website van DUO om uw diploma's te downloaden.</p>",
      type: 'text',
    },
    duoLink: {
      text: 'https://mijn.duo.nl/particulier/portaal/mijndiplomas/regulier',
      type: 'link',
    },
  },
  certificateOfConductCheck: {
    title: {
      text: '<p>Verklaring Omtrent Gedrag</p>',
      type: 'text',
    },
    description: {
      text: '<p>U wordt gevraagd om een <a href="https://www.justis.nl/producten/verklaring-omtrent-het-gedrag/wat-is-een-vog" target="_blank"> Verklaring Omtrent Gedrag (VOG) te uploaden.</a> te uploaden.Klik op de knop om toestemming te geven om de VOG van het aangegeven type aan te vragen met uw gegevens. Nadat u toestemming heeft gegeven, wordt de VOG digitaal klaargezet bij Justis. U ontvangt per email een betaallink (iDeal). Na betaling ontvangt u binnen enkele dagen (indien geen bezwaar) de VOG per post zodat u de VOG kunt uploaden.</p><p><b>Let op:</b> Bij het aanvragen van de VOG zullen er kosten in rekening worden gebracht door de overheid.</p>',
      type: 'text',
    },
  },
  confirmation: {
    title: {
      text: '<p>Bevestiging gegevens</p>',
      type: 'text',
    },
    description: {
      text: "<p>Hiermee bent u aan het einde gekomen van de screening. Hartelijk dank voor uw medewerking. Nadat u onderstaande regels hebt aangevinkt en op de knop 'Volgende' hebt geklikt, wordt de screening verzonden naar de opdrachtgever. Eventueel kan u nog opmerkingen toevoegen.</p>",
      type: 'text',
    },
    agreeText: {
      text: '<p>Ik ga ermee akkoord dat mijn gegevens enkel worden gedeeld met de opdrachtgever van de screening.</p>',
      type: 'text',
    },
    truthStatement: {
      text: '<p>Ik verklaar hiermee dat ik de screening naar waarheid heb ingevuld.</p>',
      type: 'text',
    },
  },
};

export const defaultDashboardContent: DashboardPageContent = {
  screenings: {
    deleteScreeningTitle: {
      text: '<p>Verwijder screening</p>',
      type: 'text',
    },
    deleteScreeningDescription: {
      text: '<p>Weet je zeker dat je de screening wil verwijderen. Dit verwijdert permanent alle gegevens van de kandidaat.</p>',
      type: 'text',
    },
    creditsTutorialTitle: {
      text: '<p>Credits</p>',
      type: 'text',
    },
    creditsTutorialDescription: {
      text: '<p>Het aantal credits dat u heeft. Hiermee kunt u screenings starten.</p>',
      type: 'text',
    },
    startScreeningTutorialTitle: {
      text: '<p>Start screening</p>',
      type: 'text',
    },
    startScreeningTutorialDescription: {
      text: '<p>Klik hier om een screening te starten.</p>',
      type: 'text',
    },
    screeningTableTutorialTitle: {
      text: '<p>Screening table</p>',
      type: 'text',
    },
    screeningTableDescription: {
      text: '<p>Hier ziet u een overzicht van alle screenings.</p>',
      type: 'text',
    },
    title: {
      text: 'Screenings',
      type: 'text',
    },
  },
  startScreening: {
    clarificationTitle: {
      text: '<p>Wilt u <a href="https://www.cleahr.com/duiding" target="_self">duiding</a> bij deze screening?</p>',
      type: 'text',
    },
    clarificationInfo: {
      text: '<p><strong>Wat is duiding?</strong></p>\n<ul>\n  <li>Klik op de link voor uitgebreide informatie over duiding.</li>\n  <li>Duiding kan aangevraagd worden bij CleaHR, <br />\nmaar kan ook aangevraagd worden bij een partij naar uw keuze.</li>\n  <li>Vul het emailadres in van de partij waarbij u duiding wil aanvragen.<br />\nDeze persoon kan pas duiding geven als de screening is afgerond.</li>\n</ul>',
      type: 'text',
    },
    expertClarificationTitle: {
      text: '<p>Ik wil duiding van een expert (+4 Credits)</p>',
      type: 'text',
    },
    expertClarificationInfo: {
      text: '<p><strong>Wat is duiding van een expert?</strong></p>\n<p>Als u kiest voor duiding van een expert, dan zullen de screening resultaten worden onderzocht door een onderzoeker van ons. De bevindingen worden met de resulaten van het screeningsprocess met gedeeld in één rapport. Als u zelf een emailadres kan die persoon net als de expert het rapport en de bevindingen van de zelfgekozen persoon samengevoegd in één rapport. </p>',
      type: 'text',
    },
    idCheckInfo: {
      text: '<p>Bij de ID-check wordt de identiteit van de kandidaat geverifieerd aan de hand van een officieel identiteitsbewijs met behulp van DataChecker.</p>',
      type: 'text',
    },
    backVerificationInfo: {
      text: '<p>Bij de bank verificatie wordt het bankrekeningnummer van de kandidaat gecontroleerd door middel van een iDEAL betaling van € 0,01.</p>',
      type: 'text',
    },
    incomeCheckInfo: {
      text: '<p>Bij de inkomenscheck wordt de kandidaat gevraagd om 3 loonstroken, IB60 en de laatste jaaropgave te uploaden (bij zakelijke klanten is dit alleen 3 jaarcijfers).</p>',
      type: 'text',
    },
    kvkCheckInfo: {
      text: '<p>Bij de KvK check wordt er gevraagd om een KvK-nummer van het bedrijf van de kandidaat</p>',
      type: 'text',
    },
    bkrCheckInfo: {
      text: '<p>Bij de BRK check wordt er gevraagd om een screenshot op te sturen van de kandidaat zijn kredietgegevens bij stichting BKR.</p>',
      type: 'text',
    },
    kadasterCheckInfo: {
      text: '<p>Bij de Kadastercheck wordt er gevraagd of de kandidaat een geregistreerd object heeft bij het Kadaster en zo ja, om deze in te vullen.</p>',
      type: 'text',
    },
    referenceCheckInfo: {
      text: '<p>Bij de referentiecheck wordt de kandidaat gevraagd om referenten toe te voegen. Deze referenten ontvangen een mail met een verzoek om een referentie te geven.</p>',
      type: 'text',
    },
    eductionCheckInfo: {
      text: '<p>Bij de opleidingscheck wordt de kandidaat gevraagd naar de gevolgde en behaalde opleidingen en om een diploma te uploaden.</p>',
      type: 'text',
    },
    cocCheckInfo: {
      text: '<p>Bij de VOG check wordt aan de kandidaat gevraagd om toestemming te geven voor het aanvragen van een VOG. Deze zal vervolgens digitaal worden klaargezet bij Justis. De kandidaat ontvangt een betaallink en na betaling wordt de VOG toegestuurd, waarna deze kan worden geüpload.</p>',
      type: 'text',
    },
    cocTypeInfo: {
      text: '<p>Er zijn verschillende soorten VOG\'s. Voor meer informatie over de verschillende soorten VOG\'s, <a href="https://www.justis.nl/producten/verklaring-omtrent-het-gedrag-vog/beoordeling-besluit-en-bezwaar/screeningsprofielen-vog-np" target="_self">klik hier</a>.</p>',
      type: 'text',
    },
    candidateDetailsTutorialTitle: {
      text: '<p>Algemene kandidaat informatie</p>',
      type: 'text',
    },
    candidateDetailsTutorialDescription: {
      text: '<p>Om een screening te starten heeft u de volgende informatie nodig: voornaam, achternaam, mobiel nummer en emailadres.</p>',
      type: 'text',
    },
    clarificationTutorialTitle: {
      text: '<p>Duiding</p>',
      type: 'text',
    },
    clarificationTutorialDescription: {
      text: "<p>U kunt extra duiding aanvragen bij deze screening. Deze check kan gedaan worden door een expert of door een partij naar keuze. Voor duiding door een expert van dit platform worden 4 extra credits in rekening gebracht. Wilt u uw duiding door een andere expert laten doen, neem dan contact op met 'info@cleahr.com'</p>",
      type: 'text',
    },
    screeningTypeTutorialTitle: {
      text: '<p>Type screening</p>',
      type: 'text',
    },
    screeningTypeTutorialDescription: {
      text: '<p>Selecteer de checks die u wilt uitvoeren bij deze screening. U kunt meerdere checks selecteren. U kunt ook gebruik maken van de presets.</p>',
      type: 'text',
    },
    title: {
      text: '',
      type: 'text',
    },
  },
  screeningDetails: {
    sendAgainInfo: {
      text: '<ul>\n<li>Hiermee kunt u een nieuwe uitnodiging sturen naar de kandidaat.</li>\n<li>De nieuwe uitnodiging zal naar het e-mailadres van de kandidaat worden gestuurd. De oude uitnodiging zal niet meer werken. </li>\n  <li>De nieuwe link is 30 dagen geldig.</li>\n  <li><strong>Let op:</strong> Dit is niet nodig als u het emailadres heeft aangepast, er zal automatisch een mail worden verstuurd.</li>\n</ul>',
      type: 'text',
    },
    screeningDetailsTutorialTitle: {
      text: '<p>Informatie van de screening</p>',
      type: 'text',
    },
    screeningDetailsTutorialDescription: {
      text: '<p>Hier ziet u alle informatie over de screening, onder andere de info van de kandidaat, de voltooide stappen en de door de kandidaat opgegeven referenten.</p>',
      type: 'text',
    },
    title: {
      text: '',
      type: 'text',
    },
  },
  users: {
    deleteUserTitle: {
      text: '<p>Verwijder gebruiker</p>',
      type: 'link',
    },
    deleteUserDiscription: {
      text: '<p>Weet je zeker dat je de gebruiker wil verwijderen? Dit verwijdert permanent alle gegevens van de gebruiker.</p>',
      type: 'link',
    },
    usersTutorialTitle: {
      text: '<p>Gebruikers</p>',
      type: 'link',
    },
    usersTutorialDescription: {
      text: '<p>Hier ziet u een overzicht van alle gebruikers. U kunt gebruikers aanmaken, bewerken en verwijderen. Uw hoofdgebruiker is niet verwijderbaar, deze kan alleen verwijderd worden door het bedrijf te verwijderen.</p>',
      type: 'link',
    },
    title: {
      text: '',
      type: 'link',
    },
  },
  addUser: {
    addUserTitle: {
      text: '<p>Registreer gebruiker</p>',
      type: 'text',
    },
    addUserDescription: {
      text: '<p>Hieronder kunt u een account aanmaken voor uw medewerkers. Zij zullen een mail krijgen om hun account te bevestigen. Deze mail is geldig voor 1 uur. </p>\n  <p><strong>Let op:</strong> Het account dat u heeft gebruikt voor het registreren van uw bedrijf kan niet verwijderd of van zijn administrator rechten ontdaan worden. Dit is het hoofdaccount van uw bedrijf.</p>',
      type: 'text',
    },
    userTypeInfo: {
      text: '<p><strong>Wat is het verschil?</strong></p>\n<p>Onderzoeker:</p>\n<ul>\n  <li>Kan een screening starten, inzien en eindigen.</li>\n  <li>Kan alle openstaande screenings zien.</li>\n  <li>Kan <u>geen</u> andere gebruikers inzien.</li>\n</ul>\n<p>Administrator:</p>\n<ul>\n  <li>Heeft rechten als de gebruiker, én</li>\n  <li>kan gebruikers en administrators aanmaken, aanpassen en verwijderen. </li>\n</ul>',
      type: 'text',
    },
    fullPageTutorialTitle: {
      text: '<p>Registreren gebruiker</p>',
      type: 'text',
    },
    fullPageTutorialDescription: {
      text: '<p>Hier kunt u een account aanmaken voor uw medewerkers.</p>',
      type: 'text',
    },
    nameFieldTutorialTitle: {
      text: '<p>Naam</p>',
      type: 'text',
    },
    nameFieldTutorialDescription: {
      text: '<p>Vul hier de voornaam en achternaam van de gebruiker in. Dit kan later nog aangepast worden.</p>',
      type: 'text',
    },
    emailFieldTutorialTitle: {
      text: '<p>Email</p>',
      type: 'text',
    },
    emailFieldTutorialDescription: {
      text: '<p>Vul hier het email adres van de gebruiker in.</p>',
      type: 'text',
    },
    userTypeFieldTutorialTitle: {
      text: '<p>Rollen</p>',
      type: 'text',
    },
    userTypeFieldTutorialDescription: {
      text: '<p>Hier kunt u kiezen of de gebruiker een onderzoeker of administrator is. Voor meer informatie over de rollen, beweeg uw muis op het informatie icoon.</p>',
      type: 'text',
    },
    confirmationTutorialTitle: {
      text: '<p>Aanmaken</p>',
      type: 'text',
    },
    confirmationTutorialDescription: {
      text: '<p>Klik hier om het account aan te maken. De gebruiker zal een mail krijgen om het account te bevestigen.</p>',
      type: 'text',
    },
    title: {
      text: '',
      type: 'text',
    },
  },
  credits: {
    title: {
      text: '<p>Hier kunt u uw credits kopen!</p>',
      type: 'link',
    },
    description: {
      text: '<p>Credits zullen eenmaal gekocht altijd beschikbaar blijven. U kunt na uw aankoop niet meer een refund krijgen op uw credits of uw credits omruilen.</p>',
      type: 'link',
    },
    tutorialTitle: {
      text: '<p>Credits</p>',
      type: 'link',
    },
    tutorialDescription: {
      text: '<p>Hier kunt u uw credits kopen.</p>',
      type: 'link',
    },
  },
};
