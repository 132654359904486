import { Button, Tooltip, Typography, notification, theme } from 'antd';
import { axiosInstance } from '../../utils/axios';
import { useContext, useState } from 'react';
import { InfoCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { DashboardPageContentContext } from '../../context/DashboardContentProvider';
import LoadingSpinner from '../screening-steps/util/LoadingSpinner';

const { Title } = Typography;
const { useToken } = theme;

export default function SendNewInviteLink() {
  const [messageApi, contextHolder] = notification.useNotification();
  const { token } = useToken();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [showButtonIcon, setShowButtonIcon] = useState(false);

  const content = useContext(DashboardPageContentContext);

  async function sendNewInvite() {
    setLoading(true);
    axiosInstance
      .post(`/screening/resend-email/${id}`)
      .then(() => {
        setLoading(false);
        setShowButtonIcon(true);
        messageApi.success({
          message: 'Screening uitnodiging is opnieuw verstuurd',
          placement: 'bottomRight',
        });
      })
      .catch(() => {
        setLoading(false);
        messageApi.error({
          message:
            'Er is iets fout gegaan bij het versturen van de uitnodiging. Probeer het later opnieuw.',
          placement: 'bottomRight',
        });
      });
  }

  return content === 'loading' ? (
    <LoadingSpinner />
  ) : (
    <div>
      {contextHolder}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Title level={5} style={{ marginTop: '1rem' }}>
          Opnieuw versturen uitnodiging
        </Title>{' '}
        <Tooltip
          title={<div>{content.screeningDetails.sendAgainInfo}</div>}
          overlayStyle={{ maxWidth: '30em' }}
          color={token.colorPrimary}
        >
          <InfoCircleOutlined style={{ marginLeft: '1%' }} />
        </Tooltip>
      </div>
      <Button onClick={sendNewInvite} loading={loading}>
        {showButtonIcon ? <CheckOutlined /> : null}
        Verstuur nieuwe uitnodiging
      </Button>
    </div>
  );
}
