import { Content, parsedContent } from './screeningContent.interface';

export enum DashboardSteps {
  SCREENINGS = 'screenings',
  START_SCREENING = 'startScreening',
  SCREENING_DETAILS = 'screeningDetails',
  USERS = 'users',
  ADD_USERs = 'addUser',
  CREDITS = 'credits',
}

export interface GenericDashboardPageContent<T> {
  screenings: ScreeningsPage<T>;
  startScreening: StartScreeningPage<T>;
  screeningDetails: ScreeningDetailsPage<T>;
  users: UsersPage<T>;
  addUser: AddUserPage<T>;
  credits: CreditsPage<T>;
}

interface Page<T> {
  title: T;
}

interface ScreeningsPage<T> extends Page<T> {
  deleteScreeningTitle: T;
  deleteScreeningDescription: T;

  // Tutorial steps
  creditsTutorialTitle: T;
  creditsTutorialDescription: T;
  startScreeningTutorialTitle: T;
  startScreeningTutorialDescription: T;
  screeningTableTutorialTitle: T;
  screeningTableDescription: T;
}

interface StartScreeningPage<T> extends Page<T> {
  // Clarification === duiding
  clarificationTitle: T;
  clarificationInfo: T;
  expertClarificationTitle: T;
  expertClarificationInfo: T;

  // All check info texts
  idCheckInfo: T;
  backVerificationInfo: T;
  incomeCheckInfo: T;
  kvkCheckInfo: T;
  bkrCheckInfo: T;
  kadasterCheckInfo: T;
  referenceCheckInfo: T;
  eductionCheckInfo: T;
  cocCheckInfo: T;
  cocTypeInfo: T;

  // Tutorial steps
  candidateDetailsTutorialTitle: T;
  candidateDetailsTutorialDescription: T;
  clarificationTutorialTitle: T;
  clarificationTutorialDescription: T;
  screeningTypeTutorialTitle: T;
  screeningTypeTutorialDescription: T;
}

interface ScreeningDetailsPage<T> extends Page<T> {
  sendAgainInfo: T;

  // Tutorial steps
  screeningDetailsTutorialTitle: T;
  screeningDetailsTutorialDescription: T;
}

interface UsersPage<T> extends Page<T> {
  deleteUserTitle: T;
  deleteUserDiscription: T;

  // Tutorial steps
  usersTutorialTitle: T;
  usersTutorialDescription: T;
}

interface AddUserPage<T> extends Page<T> {
  addUserTitle: T;
  addUserDescription: T;
  userTypeInfo: T;

  // Tutorial steps
  fullPageTutorialTitle: T;
  fullPageTutorialDescription: T;
  nameFieldTutorialTitle: T;
  nameFieldTutorialDescription: T;
  emailFieldTutorialTitle: T;
  emailFieldTutorialDescription: T;
  userTypeFieldTutorialTitle: T;
  userTypeFieldTutorialDescription: T;
  confirmationTutorialTitle: T;
  confirmationTutorialDescription: T;
}

interface CreditsPage<T> extends Page<T> {
  title: T;
  description: T;

  // Tutorial steps
  tutorialTitle: T;
  tutorialDescription: T;
}

export type DashboardPageContent = GenericDashboardPageContent<Content>;
export type ParsedDashboardPageContent =
  GenericDashboardPageContent<parsedContent>;
