import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useContext,
} from 'react';
import { useStep } from '../../hooks/useStep';
import {
  Card,
  theme,
  Divider,
  Tooltip,
  Upload,
  Button,
  message,
  Form,
  Radio,
  RadioChangeEvent,
  Flex,
} from 'antd';
import { axiosInstance } from '../../utils/axios';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { uploadFile } from '../../utils/uploadFile';
import { useTitle } from '../../hooks/useTitle';
import { FileUrl } from '../../shared/types/url.interface';
import { RcFile } from 'antd/es/upload/interface';
import LoadingSpinner from './util/LoadingSpinner';
import CommentField from '../CommentField/CommentField';
import { centerPopup } from '../../utils/centerPopup';
import { ScreeningContentContext } from '../../context/ScreeningContentProvider';

const { useToken } = theme;

interface IncomeCheckInput {
  personaType: string;
  comment: string;
}

const IncomeCheckStep = forwardRef((props, ref) => {
  useTitle('Inkomensverklaring');
  const { token } = useToken();
  const [value, setValue] = useState('particulier');
  const [fileCount, setFileCount] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [files, setFiles] = useState<RcFile[]>([]);
  const { goToNextStep } = useStep();
  const [comment, setComment] = useState<string>('');

  const content = useContext(ScreeningContentContext);
  const loadingContent = content === 'loading';
  const [loadingData, setLoadingData] = useState(true);
  const [submittingFiles, setSubmittingFiles] = useState(false);

  const ERROR_MESSAGE = 'Er is een fout opgetreden, probeer het later opnieuw.';
  const FILE_ERROR_MESSAGE =
    'Er is iets fout gegaan met het opslaan van het bestand. Probeer het later opnieuw.';
  const SUCCESS_MESSAGE = 'Succesvol opgeslagen';

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const openBelastingDienst = () => {
    const duoWindow = centerPopup(
      !loadingContent ? (content.incomeCheck.taxLink as string) : 'alt',
      '_blank',
      1080,
      800,
    );
    if (duoWindow) {
      duoWindow.focus();
    }
    if (!duoWindow) {
      messageApi.error(
        'Het openen van Mijn belastingdienst is niet gelukt. Controleer uw pop-up instellingen.',
      );
    }
  };

  const openKvk = () => {
    const duoWindow = centerPopup(
      !loadingContent ? (content.incomeCheck.cocLink as string) : 'alt',
      '_blank',
      1080,
      800,
    );
    if (duoWindow) {
      duoWindow.focus();
    }
    if (!duoWindow) {
      messageApi.error(
        'Het openen van KVK is niet gelukt. Controleer uw pop-up instellingen.',
      );
    }
  };

  useImperativeHandle(ref, () => ({
    save() {
      const values = form.getFieldsValue();
      const touched = form.isFieldsTouched();
      if (!touched && files.length === 0) {
        return;
      }
      form
        .validateFields({ validateOnly: true })
        .then(() => {
          handleSubmit(values);
        })
        .catch(() => {});
    },
  }));

  const handleSubmit = async (values: IncomeCheckInput) => {
    if (files.length > 0) {
      setSubmittingFiles(true);
      const fileAmount = files.length;
      await axiosInstance
        .get(`/screening/incomecheck/fileUrl/${fileAmount}`)
        .then((response) => {
          response.data.forEach((file: FileUrl, index: number) => {
            if (!file.url) {
              messageApi.error(FILE_ERROR_MESSAGE);
              throw new Error(FILE_ERROR_MESSAGE);
            }
            uploadFile(files[index], file.url)
              .then(async () => {
                await axiosInstance
                  .post('screening/incomecheck/savefile', {
                    path: file.filePath,
                  })
                  .then(() => {
                    setFileCount((prev) => prev + 1);
                  })
                  .catch(() => {
                    messageApi.error(
                      `Opslaan van bestand ${files[index].name} is mislukt`,
                    );
                  });
              })
              .catch(() => {
                messageApi.error(
                  `Opslaan van bestand ${files[index].name} is mislukt`,
                );
              });
          });
          messageApi.success(SUCCESS_MESSAGE);
          return response.data;
        })
        .catch((error) => {
          messageApi.error(ERROR_MESSAGE);
          throw error;
        })
        .finally(() => {
          setSubmittingFiles(false);
          setFiles([]);
        });
    }
    await axiosInstance
      .post('screening/incomecheck', { ...values, personaType: value })
      .catch(() => {
        messageApi.error(ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    axiosInstance
      .get('/screening/incomecheck/data')
      .then((response) => {
        if (response.data) {
          setComment(response.data.comment);
          setFileCount(response.data.pdfFilePath.length);
        }
        setLoadingData(false);
      })
      .catch(() => {
        messageApi.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw.`,
        });
        setLoadingData(false);
      });
  }, [form, messageApi]);

  return loadingData || loadingContent ? (
    <LoadingSpinner />
  ) : (
    <>
      <div>
        {contextHolder}
        <Card title={content.incomeCheck.title} style={{ maxWidth: 500 }}>
          <p>Bent u op dit moment particulier of zakelijk bezig?:</p>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={'particulier'}>Particulier</Radio>
            <Radio value={'zakelijk'}>Zakelijk</Radio>
          </Radio.Group>
          <div style={{ fontWeight: 'bold' }}>
            {content.incomeCheck.requirementTitle}
          </div>
          {value === 'particulier' && (
            <div>
              <ul className="particulier no-margin">
                {[
                  [
                    content.incomeCheck.requirementPrivateOne,
                    content.incomeCheck.requirementPrivateOneInfo,
                  ],
                  [
                    content.incomeCheck.requirementPrivateTwo,
                    content.incomeCheck.requirementPrivateTwoInfo,
                  ],
                  [
                    content.incomeCheck.requirementPrivateThree,
                    content.incomeCheck.requirementPrivateThreeInfo,
                  ],
                ].map(([requirement, requirementInfo]) => {
                  return (
                    <li>
                      <Flex>
                        {requirement}
                        <Tooltip
                          title={<div>{requirementInfo}</div>}
                          overlayStyle={{ maxWidth: '100%' }}
                          color={token.colorPrimary}
                        >
                          <InfoCircleOutlined style={{ marginLeft: '1%' }} />
                        </Tooltip>
                      </Flex>
                    </li>
                  );
                })}
              </ul>

              <Button onClick={() => openBelastingDienst()} type="primary">
                Mijn belastingdienst openen
              </Button>
            </div>
          )}
          {value === 'zakelijk' && (
            <>
              <ul className="zakelijk no-margin">
                <li>
                  <Flex>
                    {content.incomeCheck.requirementBusiness}
                    <Tooltip
                      title={
                        <div>{content.incomeCheck.requirementBusinessInfo}</div>
                      }
                      overlayStyle={{ maxWidth: '100%' }}
                      color={token.colorPrimary}
                    >
                      <InfoCircleOutlined style={{ marginLeft: '1%' }} />
                    </Tooltip>
                  </Flex>
                </li>
              </ul>

              <Button onClick={() => openKvk()} type="primary">
                Mijn KVK openen
              </Button>
            </>
          )}

          <Divider />

          <div>
            <p style={{ fontWeight: 'bold' }}>Bestanden:</p>
            <p className="fileAmount">Aantal bestanden geüpload: {fileCount}</p>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item name="fileUpload">
                <Upload
                  beforeUpload={(file) => {
                    setFiles((oldFiles) => [...oldFiles, file]);
                    return false;
                  }}
                  multiple={true}
                  accept=".pdf"
                  maxCount={5}
                  showUploadList={{ showRemoveIcon: false }}
                >
                  <Button icon={<UploadOutlined />}>Selecteer bestand</Button>
                </Upload>
              </Form.Item>
              <Divider />
              <CommentField
                ApiUrl={'screening/incomecheck/comment'}
                comment={comment}
              />
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submittingFiles}
                >
                  Opslaan
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
        <Button
          style={{
            float: 'right',
            marginTop: 20,
            marginBottom: 30,
            marginRight: 10,
          }}
          type="primary"
          onClick={() => goToNextStep()}
        >
          Volgende
        </Button>
      </div>
    </>
  );
});

export default IncomeCheckStep;
