import { useTitle } from '../../hooks/useTitle';
import { useEffect, useRef, useState } from 'react';
import LoadingSpinner from '../screening-steps/util/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { axiosInstance } from '../../utils/axios';
import {
  PaymentData,
  PaymentStatus,
  statusComponents,
} from './statusComponents';

export default function PaymentStatusComponent() {
  useTitle('Betaling status');

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [status, setStatus] = useState<PaymentStatus>(PaymentStatus.pending);
  const [paymentData, setPaymentData] = useState<PaymentData>();
  const StatusComponent = statusComponents[status];
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);
  const params = useParams();

  const pollBackend = () => {
    axiosInstance.get(`/credit-payment/${params.id}`).then((json) => {
      const status = json.data.paymentStatus;
      setPaymentData(json.data);
      setStatus(status as PaymentStatus);
      setLoadingData(false);

      // The following status types are difinitive
      if (
        status === PaymentStatus.paid ||
        status === PaymentStatus.failed ||
        status === PaymentStatus.expired ||
        status === PaymentStatus.canceled
      ) {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
      }
    });
  };

  useEffect(() => {
    pollBackend();

    intervalIdRef.current = setInterval(() => {
      pollBackend();
    }, 500); // poll every 500 milliseconds

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This hook would be called everytime pollBackend is called...
  }, []);

  return loadingData ? (
    <LoadingSpinner />
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1rem',
        marginTop: '1rem',
      }}
    >
      <StatusComponent paymentData={paymentData} />
    </div>
  );
}
