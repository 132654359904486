import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Card, Divider, theme, Typography, Checkbox } from 'antd';
import { axiosInstance } from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../hooks/useTitle';
import { CandidateAuthService } from '../../redux/services/candidateAuth.service';
import CommentField from '../CommentField/CommentField';
import { useMessage } from '../../hooks/useMessage';
import StepStatusTable from '../StepStatusTable';
import { ScreeningContentContext } from '../../context/ScreeningContentProvider';
import LoadingSpinner from './util/LoadingSpinner';
const { useToken } = theme;

const { Paragraph } = Typography;

interface StepCompletedInterface {
  steps: boolean;
  all: boolean;
}
const ConfirmationStep = () => {
  useTitle('Bevestiging');
  const { toast } = useMessage();
  const { token } = useToken();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const content = useContext(ScreeningContentContext);
  const loadingContent = content === 'loading';

  const [confirmHonestScreening, setConfirmHonestScreening] = useState(false);
  const [agreeSharingData, setAgreeSharingData] = useState(false);
  const [comment, setComment] = useState<string>('');

  const isNotAllowedToSubmit = () => {
    return !confirmHonestScreening || !agreeSharingData;
  };

  const onFinish = () => {
    setLoading(true);
    axiosInstance
      .get('screeningStepStatus/completed/current')
      .then((response) => {
        if (response?.data) {
          const completed: StepCompletedInterface = response.data;
          if (!completed.steps) {
            toast.error('Nog niet alle stappen zijn voltooid');
            setLoading(false);
            return;
          }

          axiosInstance
            .patch('/screening/confirmation', {
              agreeSharingData,
              confirmHonestScreening,
            })
            .then(() => {
              navigate('/success/confirmationSuccess');
              CandidateAuthService.logout();
            })
            .catch((error) => {
              toast.error({
                content: `Er is een fout opgetreden, probeer het later opnieuw.`,
              });
              setLoading(false);
            });
        }
      })
      .catch((e) => {
        toast.error(
          'Er is iets fout gegaan met het checken of alle stappen voltooid zijn',
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    axiosInstance
      .get('/screening/confirmation/comment')
      .then((response) => {
        if (response) {
          setComment(response.data);
        }
      })
      .catch((error) => {
        toast.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw.`,
        });
      });
  });

  return loadingContent ? (
    <LoadingSpinner />
  ) : (
    <div style={{ maxWidth: 500 }}>
      <Form onFinish={onFinish} layout="vertical" size={'middle'}>
        <Card
          title={content.confirmation.title}
          style={{
            backgroundColor: token.colorBgBase,
          }}
        >
          <Paragraph>{content.confirmation.description}</Paragraph>

          <StepStatusTable
            getScreening={true}
            disableReferentsInterpretation={true}
            disableConfirmation={true}
            widthStyle="40%"
          />
          <Divider />
          <Form.Item name="agreeSharingData">
            <Checkbox
              style={{ width: '100%' }}
              checked={agreeSharingData}
              onChange={(event) => setAgreeSharingData(event.target.checked)}
            >
              {content.confirmation.agreeText}
            </Checkbox>
          </Form.Item>
          <Form.Item name="confirmHonestScreening">
            <Checkbox
              style={{ width: '100%' }}
              checked={confirmHonestScreening}
              onChange={(event) =>
                setConfirmHonestScreening(event.target.checked)
              }
            >
              {content.confirmation.truthStatement}
            </Checkbox>
          </Form.Item>
          <Divider />
          <CommentField
            ApiUrl={'screening/confirmation/comment'}
            comment={comment}
          />
        </Card>
        <Form.Item>
          <Button
            style={{
              float: 'right',
              marginTop: 20,
              marginBottom: 30,
              marginRight: 10,
            }}
            type="primary"
            htmlType="submit"
            disabled={isNotAllowedToSubmit()}
            loading={loading}
          >
            Bevestig
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConfirmationStep;
