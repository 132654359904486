import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  Form,
  Divider,
  theme,
  Descriptions,
  Typography,
  DescriptionsProps,
  notification,
} from 'antd';
import { axiosInstance } from '../../utils/axios';
import { useStep } from '../../hooks/useStep';
import { AxiosError } from 'axios';
import { useTitle } from '../../hooks/useTitle';
import LoadingSpinner from './util/LoadingSpinner';
import CommentField from '../CommentField/CommentField';
import { useMessage } from '../../hooks/useMessage';
import { ScreeningContentContext } from '../../context/ScreeningContentProvider';
const { useToken } = theme;

const { Paragraph } = Typography;

const Bankverification = () => {
  useTitle('Bankverificatie');
  const { goToNextStep } = useStep();
  const { token } = useToken();
  const [paymentDetails, setPaymentDetails] =
    useState<DescriptionsProps['items']>();
  const [form] = Form.useForm();
  const { toast } = useMessage();

  const content = useContext(ScreeningContentContext);
  const loadingContent = content === 'loading';
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingMollie, setLoadingMollie] = useState(false);
  const [comment, setComment] = useState<string>('');

  async function getPaymentDetails() {
    try {
      const res = await axiosInstance.get(`/screening/bank-verification/`);
      setComment(res.data.bankComment);
      if (res.data.status && res.data.status !== 'Betaald') {
        toast.error({
          content: `Uw bankverificatie is mislukt, probeer het opnieuw.`,
        });
        setLoadingData(false);
        return;
      }
      if (
        res.data.iban &&
        res.data.nameOfBankAccountHolder &&
        res.data.status
      ) {
        setPaymentDetails([
          {
            key: '1',
            label: 'Bankrekeningnummer',
            children: res.data.iban,
          },
          {
            key: '2',
            label: 'Naam',
            children: res.data.nameOfBankAccountHolder,
          },
          {
            key: '3',
            label: 'Status',
            children: res.data.status,
          },
        ]);
      }
      if (res.data.status !== 'Betaald') {
        toast.error({
          content: `Uw bankverificatie is mislukt, probeer het opnieuw.`,
        });
        setLoadingData(false);
      }
      form.setFieldValue('comment', res.data.bankComment);
      setLoadingData(false);
      return;
    } catch (error) {
      if (!(error instanceof AxiosError)) {
        setLoadingData(false);
        return;
      }
      const { response } = error;
      if (!response || response.status !== 400) {
        setLoadingData(false);
        return notification.error({
          message: 'Bankverificatie',
          description: 'Er is een fout opgetreden, probeer het opnieuw.',
        });
      }

      switch (response.data.message) {
        case 'Payment not found':
          setLoadingData(false);
          return;
        case 'Payment not paid':
          setLoadingData(false);
          return notification.error({
            message: 'Bankverificatie',
            description:
              'Uw bankverificatie is nog niet afgerond, probeer het opnieuw.',
          });
        default:
          setLoadingData(false);
          return;
      }
    }
  }
  useEffect(() => {
    getPaymentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startBankverification = async () => {
    setLoadingMollie(true);
    axiosInstance
      .post('/screening/bank-verification')
      .then((response) => {
        if (response.data.paymentId) {
          localStorage.setItem('paymentId', response.data.paymentId);
        }
        window.open(response.data.paymentUrl, '_self');
      })
      .catch((error) => {
        toast.error({
          content: `Er is een fout opgetreden, probeer het later opnieuw.`,
        });
      })
      .finally(() => {
        setLoadingMollie(false);
      });
  };

  const nextStep = async () => {
    setLoading(true);
    goToNextStep();
  };

  return loadingData || loadingContent ? (
    <LoadingSpinner />
  ) : (
    <div style={{ maxWidth: 500 }}>
      <Card
        title={content.bankDetails.title}
        style={{
          backgroundColor: token.colorBgBase,
        }}
      >
        <Paragraph>{content.bankDetails.description}</Paragraph>
        <Divider />
        {!paymentDetails ? (
          <Button
            type="primary"
            onClick={startBankverification}
            loading={loadingMollie}
          >
            Start bankverificatie
          </Button>
        ) : (
          <Descriptions
            items={paymentDetails}
            layout="vertical"
            colon={false}
            column={1}
          />
        )}
        <Divider />
        <CommentField
          ApiUrl={'/screening/bank-verification/comment'}
          comment={comment}
        />
      </Card>
      <Button
        style={{
          float: 'right',
          marginTop: 20,
          marginBottom: 30,
          marginRight: 10,
        }}
        type="primary"
        onClick={() => nextStep()}
        loading={loading}
      >
        Volgende
      </Button>
    </div>
  );
};

export default Bankverification;
