import { useContext, useEffect, useState } from 'react';
import { disableDashboardTour } from '../../utils/dashboardTourFunctions';
import { TourProps } from 'antd';
import { useMediaQuery } from '../useMediaQuery';
import {
  DashboardPageContentContext,
  removeP,
} from '../../context/DashboardContentProvider';

export function useDashBoardUserTour(
  getRef: (key: string) => React.RefObject<HTMLElement>,
) {
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>();
  const [tourOpen, setTourOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const content = useContext(DashboardPageContentContext);

  useEffect(() => {
    if (content === 'loading') return;
    setTourSteps([
      {
        title: removeP(content.users.usersTutorialTitle as string),
        description: removeP(content.users.usersTutorialDescription as string),
        target: getRef('users-table')?.current,
      },
    ]);

    if (localStorage.getItem('tour-dashboard-users') && !isMobile) {
      setTourOpen(true);
      disableDashboardTour('tour-dashboard-users');
    }
  }, [isMobile, getRef, content]);

  return { tourSteps, setTourOpen, tourOpen };
}
