import { useEffect, useState } from 'react';
import { axiosInstance } from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Divider, Form, message, Modal } from 'antd';
import { useTitle } from '../../hooks/useTitle';
import OtpInput from 'react-otp-input';

interface TwoFactorData {
  qrCode: string;
  secret: string;
}

export default function TwoFactorSetup() {
  useTitle('2FA instellen');
  const [messageApi, contextHolder] = message.useMessage();
  const [inputValue, setInputValue] = useState<string>('');
  const [countdown, setCountdown] = useState(5);
  const [TwoFactorData, setTwoFactorData] = useState<TwoFactorData>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = async (values: { code: string }) => {
    localStorage.removeItem('user');
    axiosInstance
      .post('/auth/2fa/turn-on', values)
      .then((res) => {
        setTimeout(
          () => {
            navigate('/dashboard');
          },
          parseInt(process.env.BACKEND_PORT ?? '5000'),
        );
        setIsModalOpen(true);
      })
      .catch((err) => {
        if (err.response?.data.message === 'Wrong authentication code') {
          form.setFields([
            {
              name: 'code',
              errors: ['Verkeerde code, probeer het opnieuw'],
            },
          ]);
          return messageApi.error('Verkeerde code, probeer het opnieuw');
        }
        messageApi.error('Er is iets misgegaan');
      });
  };

  useEffect(() => {
    axiosInstance
      .get('/auth/2fa/generate')
      .then((res) => {
        setTwoFactorData(res.data);
      })
      .catch((err) => {
        messageApi.error(
          'Er is iets misgegaan bij het genereren van de QR code',
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let countdownInterval: NodeJS.Timer;

    if (isModalOpen) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [isModalOpen]);

  useEffect(() => {
    if (countdown === 0) {
      setIsModalOpen(false);
    }
  }, [countdown]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {contextHolder}
      <Card title="Registreren" style={{ maxWidth: 500 }}>
        <div>
          We vragen u om 2FA in te stellen om uw account te beveiligen, en
          hiermee de veiligheid van uw bedrijf en kandidaten te waarborgen.
          <p>
            Scan de QR code met een authenticator app zoals Google Authenticator
            of Authy.
          </p>
        </div>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={TwoFactorData?.qrCode} alt="QR code" />
        </div>
        <p style={{ textAlign: 'center' }}>{TwoFactorData?.secret}</p>
        <Divider />

        <Form
          onFinish={() =>
            handleSubmit({
              code: inputValue,
            })
          }
          layout="vertical"
          form={form}
        >
          <Form.Item name="code" label="Code" required>
            <OtpInput
              value={inputValue}
              onChange={(v) => {
                setInputValue(v);
                v.length === 6 &&
                  handleSubmit({
                    code: v,
                  });
              }}
              shouldAutoFocus={true}
              containerStyle={{
                display: 'block',
                width: '100%',
                margin: '0 auto',
              }}
              numInputs={6}
              inputStyle={{
                width: '2rem',
                height: '2rem',
                margin: '0 0.5rem',
                fontSize: '2rem',
                textAlign: 'center',
                borderRadius: '4px',
                border: '1px solid #d9d9d9',
              }}
              renderInput={(props) => <input {...props} />}
            />{' '}
          </Form.Item>
          <Form.Item
            style={{ display: 'flex', justifyContent: 'end', width: '100%' }}
          >
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Registreren
            </Button>
          </Form.Item>
        </Form>
        <Modal
          title="Successvolle registratie"
          open={isModalOpen}
          footer={null}
          closable={false}
          maskClosable={false}
        >
          <p>
            2FA is succesvol ingesteld en daarmee is de registratie voltooid. U
            wordt over {countdown} seconden doorgestuurd naar het login scherm.
          </p>
        </Modal>
      </Card>
    </div>
  );
}
