import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu, MenuProps } from 'antd';
import { AuthService } from '../../redux/services/auth.service';
import { useNavigate } from 'react-router-dom';
import {
  LogoutOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import UserRole from '../../shared/enums/user-role.enum';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { MenuOutlined } from '@ant-design/icons';

interface NavigationProps {
  setTourOpen?: ((value: boolean) => void) | undefined;
}

const Navigation: React.FC<NavigationProps> = ({ setTourOpen }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const user = AuthService.getCurrentUser();
  const navigate = useNavigate();
  const paths = window.location.pathname.split('/');
  const [items, setItems] = useState<MenuProps['items']>([]);
  const currentPage = paths[paths.length - 1];

  useEffect(() => {
    const getMenuItems = () => {
      const items: MenuProps['items'] = [
        {
          key: 'dashboard',
          onClick: () => navigate('/dashboard'),
          label: 'Screenings',
        },
      ];

      if (user?.role === UserRole.ADMIN) {
        items.push({
          key: 'users',
          onClick: () => navigate('/dashboard/users'),
          label: 'Gebruikers',
        });
        items.push({
          key: 'credit-store',
          onClick: () => navigate('/dashboard/credit-store'),
          label: 'Credits Kopen',
        });
      }

      if (user?.isSuperAdmin) {
        items.push({
          key: 'company',
          onClick: () => navigate('/dashboard/company'),
          label: 'Bedrijven',
        });

        items.push({
          key: 'configuration',
          onClick: () => navigate('/dashboard/configuration'),
          label: 'Configuratie',
        });
      }

      items.push({
        key: 'logout',
        onClick: () => {
          AuthService.logout().then(() => {
            navigate('/login');
          });
        },
        label: (
          <>
            <LogoutOutlined /> <span>Uitloggen</span>
          </>
        ),
      });

      setItems(items);
    };

    getMenuItems();
  }, [navigate, user?.isSuperAdmin, user?.role]);

  const handleTourClick = () => {
    if (setTourOpen) {
      setTourOpen(true);
    }
  };

  return (
    <>
      {isMobile ? (
        <Dropdown menu={{ items }}>
          <Button>
            <MenuOutlined />
          </Button>
        </Dropdown>
      ) : (
        <Menu
          mode="horizontal"
          style={{
            width: '100%',
            maxWidth: 600,
            background: 'var(--main-blue)',
            color: 'white',
            justifyContent: 'jusitfy-end',
          }}
          selectedKeys={[currentPage]}
        >
          <Menu.Item key="dashboard" onClick={() => navigate('/dashboard')}>
            Screenings
          </Menu.Item>

          {user?.role === UserRole.ADMIN && (
            <>
              <Menu.Item
                key="users"
                onClick={() => navigate('/dashboard/users')}
              >
                Gebruikers
              </Menu.Item>
              <Menu.Item
                key="credit-store"
                onClick={() => navigate('/dashboard/credit-store')}
              >
                Credits Kopen
              </Menu.Item>
            </>
          )}
          {user?.isSuperAdmin && (
            <Menu.Item
              key="company"
              onClick={() => navigate('/dashboard/company')}
            >
              Bedrijven
            </Menu.Item>
          )}
          {user?.isSuperAdmin && (
            <Menu.Item
              key="configuration"
              onClick={() => navigate('/dashboard/configuration')}
            >
              Configuratie
            </Menu.Item>
          )}
          <Menu.SubMenu
            key="more"
            title={<MoreOutlined style={{ padding: 5 }} />}
          >
            <Menu.Item
              key="logout"
              onClick={() => {
                AuthService.logout().then(() => {
                  navigate('/login');
                });
              }}
              icon={<LogoutOutlined />}
            >
              Uitloggen
            </Menu.Item>
            {setTourOpen && (
              <Menu.Item
                key="tour"
                onClick={handleTourClick}
                icon={<QuestionCircleOutlined />}
              >
                Tutorial
              </Menu.Item>
            )}
          </Menu.SubMenu>
        </Menu>
      )}
    </>
  );
};

export default Navigation;
