import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Steps,
  Dropdown,
  Menu,
  Button,
  Space,
  StepsProps,
  Spin,
  notification,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { updateSteps, updateCurrentStep } from '../../redux/slices/steps.slice';
import { CheckOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { axiosInstance } from '../../utils/axios';
import '../../styles/screening.css';
import { ScreeningStep } from '../../redux/slices/steps.slice';
import PersonaStep from '../../components/screening-steps/PersonaStep';
import LegitimatieStep from '../../components/screening-steps/LegitimatieStep';
import Bankverification from '../../components/screening-steps/Bankverification';
import IncomeCheckStep from '../../components/screening-steps/IncomeCheckStep';
import KvkCheck from '../../components/screening-steps/KvkCheck';
import KadasterStep from '../../components/screening-steps/KadasterStep';
import BKRRegistrationStep from '../../components/screening-steps/BKRRegistrationStep';
import LandlordStatementStep from '../../components/screening-steps/LandlordStatementStep';
import ConfirmationStep from '../../components/screening-steps/ConfirmationStep';
import { Steps as StepsEnum, StepKeys } from '../../shared/enums/steps.enum';
import { availableSteps } from '../../utils/allSteps';
import EducationStep from '../../components/screening-steps/EducationStep';
import CertificateOfConductStep from '../../components/screening-steps/CertificateOfConductStep/CertificateOfConductStep';
import { useMessage } from '../../hooks/useMessage';
import EmployeeIntegrityStep from '../../components/screening-steps/EmployeeIntegrityStep';
import { ScreeningContentProvider } from '../../context/ScreeningContentProvider';

interface SaveRef {
  save: () => void;
}

const Screening = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useMessage();
  const [loading, setLoading] = useState(false);
  const [completedSteps, setCompletedSteps] = useState<string[]>(['lol']);
  const steps = useSelector((state: RootState) => state.steps.availableSteps);
  const current = useSelector((state: RootState) => state.steps.currentStep);
  const [messageApi, contextHolder] = notification.useNotification();

  const refs = {
    [StepKeys.personalData]: useRef<SaveRef>(null),
    [StepKeys.identification]: useRef<SaveRef>(null),
    [StepKeys.bankData]: useRef<SaveRef>(null),
    [StepKeys.incomeCheck]: useRef<SaveRef>(null),
    [StepKeys.kvk]: useRef<SaveRef>(null),
    [StepKeys.kadaster]: useRef<SaveRef>(null),
    [StepKeys.bkr]: useRef<SaveRef>(null),
    [StepKeys.landlordStatement]: useRef<SaveRef>(null),
    [StepKeys.employeeIntegrity]: useRef<SaveRef>(null),
    [StepKeys.confirmation]: useRef<SaveRef>(null),
    [StepKeys.education]: useRef<SaveRef>(null),
    [StepKeys.certificateOfConduct]: useRef<SaveRef>(null),
  };

  function renderCurrentStep(currentKey: string | undefined) {
    switch (currentKey) {
      case StepKeys.personalData:
        return <PersonaStep ref={refs[StepKeys.personalData]} />;
      case StepKeys.identification:
        return <LegitimatieStep />;
      case StepKeys.bankData:
        return <Bankverification />;
      case StepKeys.incomeCheck:
        return <IncomeCheckStep ref={refs[StepKeys.incomeCheck]} />;
      case StepKeys.kvk:
        return <KvkCheck ref={refs[StepKeys.kvk]} />;
      case StepKeys.kadaster:
        return <KadasterStep ref={refs[StepKeys.kadaster]} />;
      case StepKeys.bkr:
        return <BKRRegistrationStep ref={refs[StepKeys.bkr]} />;
      case StepKeys.employeeIntegrity:
        return <EmployeeIntegrityStep />;
      case StepKeys.landlordStatement:
        return <LandlordStatementStep />;
      case StepKeys.education:
        return <EducationStep />;
      case StepKeys.certificateOfConduct:
        return <CertificateOfConductStep />;
      case StepKeys.confirmation:
        return <ConfirmationStep />;
    }
  }

  useEffect(() => {
    axiosInstance.get(`screeningStepStatus/current`).then((response) => {
      if (!response.data)
        messageApi.error({
          message:
            'Er is iets fout gegaan bij het ophalen van de voltooide stappen',
          placement: 'bottomRight',
        });
      const completedSteps: string[] = [];
      for (const step of availableSteps)
        if (response.data[step.key]) completedSteps.push(step.title);

      setCompletedSteps(completedSteps);
    });
  }, [messageApi]);

  useEffect(() => {
    async function buildStepsArray(stepsArray: string[]) {
      const tempSteps: ScreeningStep[] = [];
      stepsArray.forEach((step, index) => {
        const stepItem = availableSteps.find((item) => item.key === step);
        if (!stepItem) return;
        tempSteps.push(stepItem);
      });
      dispatch(updateSteps(tempSteps));
      return tempSteps;
    }
    setLoading(true);
    axiosInstance
      .get('/screening/status/')
      .then((response) => {
        let stepIndex;
        buildStepsArray(response.data.steps).then((stepsArray) => {
          setLoading(false);
          if (response.data.status === 'notStarted') {
            axiosInstance.put(
              `/screening/update-status/${StepsEnum.personalData}`,
            );
            navigate(`/screening?step=${StepsEnum.personalData}`);
            stepIndex = 0;
          } else {
            navigate(`/screening?step=${response.data.status}`);
            stepIndex = stepsArray.findIndex(
              (step) => step.url === response.data.status,
            );
          }
          if (stepIndex !== -1) {
            dispatch(updateCurrentStep(stepIndex));
          }
          return;
        });
      })
      .catch((error) => {
        toast.open({
          type: 'error',
          content: error.message,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, toast]);

  function handleChangeStep(step: number) {
    const currentStep = steps[current].key;
    if (
      currentStep === StepKeys.notStarted ||
      currentStep === StepKeys.completed
    )
      return;

    refs[currentStep].current?.save();

    // Update the step-status
    axiosInstance.put(`/screening/update-status/${steps[step].url}`);
    navigate(`/screening?step=${steps[step].url}`);
    if (step !== -1) {
      dispatch(updateCurrentStep(step));
    }
  }
  const customDot: StepsProps['progressDot'] = (
    dot,
    { status, index, title },
  ) => {
    if (!completedSteps.includes(title as string) || index === current)
      return <span>{dot}</span>;
    else
      return (
        <div style={{ marginLeft: -3, marginTop: -3 }}>
          <CheckOutlined />
        </div>
      );
  };

  const hamburgerMenu = (
    <Menu>
      {steps.map((step, index) => (
        <Menu.Item
          key={index}
          onClick={() => handleChangeStep(index)}
          style={current === index ? { color: 'blue' } : {}}
          icon={
            completedSteps.includes(step.title) ? <CheckOutlined /> : undefined
          }
          itemIcon
        >
          {step.title}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <ScreeningContentProvider>
      <>
        {contextHolder}
        <div className="screening-steps-dropdown">
          <Dropdown overlay={hamburgerMenu}>
            <Button>
              <Space>
                <UnorderedListOutlined />
                Steps
              </Space>
            </Button>
          </Dropdown>
        </div>
        <Spin spinning={loading} fullscreen />
        <div className="screening-steps">
          <Steps
            style={{ paddingTop: '20px' }}
            current={current}
            items={steps}
            onChange={handleChangeStep}
            progressDot={customDot}
          />
        </div>
        <div className="content">{renderCurrentStep(steps[current]?.key)}</div>
      </>
    </ScreeningContentProvider>
  );
};

export default Screening;
