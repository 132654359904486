import {
  LoadingOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { Button, theme } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CSSProperties } from 'react';

const { useToken } = theme;

export enum PaymentStatus {
  open = 'open',
  canceled = 'canceled',
  pending = 'pending',
  authorized = 'authorized',
  expired = 'expired',
  failed = 'failed',
  paid = 'paid',
}

export interface PaymentData {
  id: string;
  credits: string;
  price: number;
  paymentId: string;
  paymentStatus: PaymentStatus;
  createdAt: Date;
}

export enum Credits {
  TEN = 'TEN',
  TWENTY = 'TWENTY',
  FIFTY = 'FIFTY',
}

export const creditsToNumber: Record<Credits, number> = {
  [Credits.FIFTY]: 50,
  [Credits.TWENTY]: 20,
  [Credits.TEN]: 10,
};

export const statusComponents: Record<
  PaymentStatus,
  (props: { paymentData?: PaymentData }) => JSX.Element
> = {
  // Mollie API: https://docs.mollie.com/docs/status-change
  [PaymentStatus.open]: Open,
  [PaymentStatus.canceled]: Canceled,
  // Pending is a state that occurs (for a very short period of time) before it's open
  // Mollie API says we don't have to handle this case
  [PaymentStatus.pending]: Open,
  [PaymentStatus.expired]: Expired,
  [PaymentStatus.failed]: Failed,
  [PaymentStatus.paid]: Paid,
  // Authorized should not occur because we are only dealing with iDEAL, but just in case
  [PaymentStatus.authorized]: Failed,
};

interface StatusComponentProps {
  text: string;
  showBackButton?: boolean;
  Icon: React.ComponentType<{ style?: CSSProperties }>;
  iconColour?: string;
  paymentData?: PaymentData;
}

function StatusComponent({
  text,
  showBackButton,
  Icon,
  iconColour,
  paymentData,
}: StatusComponentProps) {
  const navigate = useNavigate();
  const { token } = useToken();
  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {showBackButton && (
        <Button
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
          }}
          onClick={() => navigate('/dashboard')}
        >
          Ga terug
        </Button>
      )}

      <div style={{ textAlign: 'center' }}>
        <h1>
          Betaling voor {creditsToNumber[paymentData?.credits as Credits]}{' '}
          credits
        </h1>
        <Icon
          style={{ fontSize: 100, color: iconColour || token.colorPrimary }}
        />
        <p style={{ marginTop: '20px', fontSize: '16px' }}>{text}</p>
      </div>
    </div>
  );
}

function Open({ paymentData }: { paymentData?: PaymentData }) {
  return (
    <StatusComponent
      text="Uw betaling wordt verwerkt..."
      Icon={LoadingOutlined}
      paymentData={paymentData}
    />
  );
}

function Expired({ paymentData }: { paymentData?: PaymentData }) {
  return (
    <StatusComponent
      text="Uw betaling is verlopen."
      Icon={WarningOutlined}
      iconColour="orange"
      paymentData={paymentData}
      showBackButton
    />
  );
}

function Canceled({ paymentData }: { paymentData?: PaymentData }) {
  return (
    <StatusComponent
      text="Uw betaling is geannuleerd."
      Icon={WarningOutlined}
      iconColour="orange"
      paymentData={paymentData}
      showBackButton
    />
  );
}

function Failed({ paymentData }: { paymentData?: PaymentData }) {
  return (
    <StatusComponent
      text="Uw betaling is mislukt. Probeer het nog een keer"
      Icon={WarningOutlined}
      iconColour="red"
      paymentData={paymentData}
      showBackButton
    />
  );
}

function Paid({ paymentData }: { paymentData?: PaymentData }) {
  return (
    <StatusComponent
      text="Uw betaling is gelukt!"
      Icon={CheckCircleOutlined}
      iconColour="green"
      paymentData={paymentData}
      showBackButton
    />
  );
}
