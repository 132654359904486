import { useContext, useEffect, useState } from 'react';
import { disableDashboardTour } from '../../utils/dashboardTourFunctions';
import { TourProps } from 'antd';
import { useMediaQuery } from '../useMediaQuery';
import { DashboardPageContentContext } from '../../context/DashboardContentProvider';

export function useStartScreeningTour(
  getRef: (key: string) => React.RefObject<HTMLElement>,
) {
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>();
  const [tourOpen, setTourOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const content = useContext(DashboardPageContentContext);

  useEffect(() => {
    if (content === 'loading') return;
    setTourSteps([
      {
        title: content.startScreening.candidateDetailsTutorialTitle,
        description: content.startScreening.candidateDetailsTutorialDescription,
        target: getRef('general')?.current,
      },
      {
        title: content.startScreening.clarificationTutorialTitle,
        description: content.startScreening.clarificationTutorialDescription,
        target: getRef('interpretation')?.current,
      },
      {
        title: content.startScreening.screeningTypeTutorialTitle,
        description: content.startScreening.screeningTypeTutorialDescription,
        target: getRef('checks')?.current,
      },
    ]);

    if (localStorage.getItem('tour-dashboard-start-screening') && !isMobile) {
      setTourOpen(true);
      disableDashboardTour('tour-dashboard-start-screening');
    }
  }, [isMobile, getRef, content]);

  return { tourSteps, setTourOpen, tourOpen };
}
