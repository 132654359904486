import { CheckCircleOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import {
  ConfigurationScreens,
  ContentSteps,
} from '../../shared/interfaces/screeningContent.interface';
import { DashboardSteps } from '../../shared/interfaces/dashboardContent.interface';

export function ContentFilterButton({
  content,
  currentScreen,
  setContent,
}: {
  content: ContentSteps | DashboardSteps;
  currentScreen: ConfigurationScreens;
  setContent: (newSteps: ContentSteps) => void;
}) {
  const screeningItemList = [
    {
      key: '1',
      content: ContentSteps.PERSONADETAILS,
      title: 'Persoonlijke gegevens',
    },
    {
      key: '2',
      content: ContentSteps.IDENTIFICATION,
      title: 'Legitimatie Check',
    },
    {
      key: '3',
      content: ContentSteps.BANKDETAILS,
      title: 'Bankverificatie',
    },
    {
      key: '4',
      content: ContentSteps.INCOMECHECK,
      title: 'Inkomens Verklaring',
    },
    {
      key: '5',
      content: ContentSteps.KVKCHECK,
      title: 'KVK Check',
    },
    {
      key: '6',
      content: ContentSteps.BKRCHECK,
      title: 'BKR Registratie',
    },
    {
      key: '7',
      content: ContentSteps.KADASTERCHECK,
      title: 'Kadaster gegevens',
    },
    {
      key: '8',
      content: ContentSteps.OWNWORKSTATEMENT,
      title: 'Eigen verklaring',
    },
    {
      key: '9',
      content: ContentSteps.WORKREFERENCESTATEMENT,
      title: 'Referentie verklaring',
    },
    {
      key: '10',
      content: ContentSteps.OWNRENTSTATEMENT,
      title: 'Eigen Verklaring - Algemeen',
    },
    {
      key: '11',
      content: ContentSteps.OWNRENTSTATEMENTRENTAL,
      title: 'Eigen verklaring - Huurwoning',
    },
    {
      key: '12',
      content: ContentSteps.LANDLORDSTATEMENT,
      title: 'Verhuurdersverklaring',
    },
    {
      key: '13',
      content: ContentSteps.EDUCATIONCHECK,
      title: 'Opleiding',
    },
    {
      key: '14',
      content: ContentSteps.COCCHECK,
      title: 'VOG Check',
    },
    {
      key: '15',
      content: ContentSteps.CONFIRMATION,
      title: 'Bevestig gegevens',
    },
  ];

  const conversion = (item: any) => {
    return {
      key: item.key,
      label: (
        <div
          style={{
            display: 'flex',
            justifyItems: 'between',
            gap: 5,
            alignItems: 'center',
          }}
        >
          {content === item.content && <CheckCircleOutlined />}{' '}
          <span>{item.title}</span>
        </div>
      ),
      onClick: () => {
        setContent(item.content);
      },
    };
  };

  const screeningItems = screeningItemList.map(conversion);

  const dashboardItemList = [
    {
      key: '1',
      content: 'screenings',
      title: 'Screenings',
    },
    {
      key: '2',
      content: 'startScreening',
      title: 'Begin Screening',
    },
    {
      key: '3',
      content: 'screeningDetails',
      title: 'Screening Details',
    },
    {
      key: '4',
      content: 'users',
      title: 'Gebruikers',
    },
    {
      key: '5',
      content: 'addUser',
      title: 'Gebruiker Toevoegen',
    },
    {
      key: '6',
      content: 'credits',
      title: 'Credits Kopen',
    },
  ];

  const dashboardItems = dashboardItemList.map(conversion);

  const contentNames = (step: ContentSteps | DashboardSteps): string => {
    switch (step) {
      case ContentSteps.PERSONADETAILS:
        return 'Persoonlijke gegevens';
      case ContentSteps.IDENTIFICATION:
        return 'Legitimatie Check';
      case ContentSteps.BANKDETAILS:
        return 'Bankverificatie';
      case ContentSteps.INCOMECHECK:
        return 'Inkomens Verklaring';
      case ContentSteps.KVKCHECK:
        return 'KVK Check';
      case ContentSteps.BKRCHECK:
        return 'BKR Registratie';
      case ContentSteps.KADASTERCHECK:
        return 'Kadaster gegevens';
      case ContentSteps.OWNWORKSTATEMENT:
        return 'Eigen verklaring';
      case ContentSteps.WORKREFERENCESTATEMENT:
        return 'Referentie verklaring';
      case ContentSteps.OWNRENTSTATEMENT:
        return 'Eigen verklaring - Algemeen';
      case ContentSteps.OWNRENTSTATEMENTRENTAL:
        return 'Eigen verklaring - Huurwoning';
      case ContentSteps.LANDLORDSTATEMENT:
        return 'Verhuurdersverklaring';
      case ContentSteps.EDUCATIONCHECK:
        return 'Opleiding';
      case ContentSteps.COCCHECK:
        return 'VOG Check';
      case ContentSteps.CONFIRMATION:
        return 'Bevestig gegevens';
      case DashboardSteps.SCREENINGS:
        return 'Screenings';
      case DashboardSteps.START_SCREENING:
        return 'Start Screening';
      case DashboardSteps.SCREENING_DETAILS:
        return 'Screening Details';
      case DashboardSteps.USERS:
        return 'Gebruikers';
      case DashboardSteps.ADD_USERs:
        return 'Gebruiker Toevoegen';
      case DashboardSteps.CREDITS:
        return 'Credit Store';
    }
    return 'Error';
  };

  return (
    <Dropdown
      menu={{
        items:
          currentScreen === ConfigurationScreens.SCREENINGS
            ? screeningItems
            : dashboardItems,
      }}
    >
      <Button type="link">
        {contentNames(content)}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}
