import { Button, Dropdown } from 'antd';
import { ContentLanguage } from '../../shared/interfaces/screeningContent.interface';
import { CheckCircleOutlined, DownOutlined } from '@ant-design/icons';

export function ContentLanguageButton({
  language,
  setLanguage,
}: {
  language: ContentLanguage;
  setLanguage: (newLanguage: ContentLanguage) => void;
}) {
  const languagesList = [
    {
      key: '1',
      language: ContentLanguage.NL,
      title: 'Nederlands',
    },
    {
      key: '2',
      language: ContentLanguage.EN,
      title: 'Engels',
    },
  ];

  const conversion = (item: any) => {
    return {
      key: item.key,
      label: (
        <div
          style={{
            display: 'flex',
            justifyItems: 'between',
            gap: 5,
            alignItems: 'center',
          }}
        >
          {language === item.language && <CheckCircleOutlined />}{' '}
          <span>{item.title}</span>
        </div>
      ),
      onClick: () => {
        setLanguage(item.language);
      },
    };
  };

  const languages = languagesList.map(conversion);

  const languageNames = (language: ContentLanguage): string => {
    switch (language) {
      case ContentLanguage.NL:
        return 'Nederlands';
      case ContentLanguage.EN:
        return 'Engels';
    }
  };

  return (
    <Dropdown
      menu={{
        items: languages,
      }}
    >
      <Button type="link">
        {languageNames(language)}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}
