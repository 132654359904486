import { useEffect, useState } from 'react';
import ContentTable from '../../../components/ContentTable/ContentTable';
import DashboardLayout from '../../../components/layout/dashboard';
import { useTitle } from '../../../hooks/useTitle';
import { Button, Dropdown, Menu, MenuProps } from 'antd';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { MenuOutlined } from '@ant-design/icons';
import { ConfigurationScreens } from '../../../shared/interfaces/screeningContent.interface';
import '../../../styles/configuration.css';

const ConfigurationDashboard = () => {
  useTitle('Configuratie');
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [currentScreen, setCurrentScreen] = useState<ConfigurationScreens>(
    ConfigurationScreens.SCREENINGS,
  );
  const [items, setItems] = useState<MenuProps['items']>([]);

  useEffect(() => {
    const items: MenuProps['items'] = [
      {
        key: 'dashboard',
        onClick: () => setCurrentScreen(ConfigurationScreens.DASHBOARD),
        label: 'Dashboard',
      },
      {
        key: 'screenings',
        onClick: () => setCurrentScreen(ConfigurationScreens.SCREENINGS),
        label: 'Screenings',
      },
    ];

    setItems(items);
  }, []);

  return (
    <DashboardLayout>
      <div style={{ flexDirection: 'row' }}>
        {isMobile ? (
          <Dropdown menu={{ items }}>
            <Button>
              <MenuOutlined />
            </Button>
          </Dropdown>
        ) : (
          <Menu
            mode="horizontal"
            style={{
              width: '100%',
              marginTop: 20,
              background: 'var(--background)',
              color: 'black',
              justifyContent: 'jusitfy-start',
            }}
            selectedKeys={[currentScreen]}
          >
            <Menu.Item
              style={{ color: 'black' }}
              key="screenings"
              onClick={() => setCurrentScreen(ConfigurationScreens.SCREENINGS)}
            >
              Screenings
            </Menu.Item>
            <Menu.Item
              style={{ color: 'black' }}
              key="dashboard"
              onClick={() => setCurrentScreen(ConfigurationScreens.DASHBOARD)}
            >
              Dashboard
            </Menu.Item>
          </Menu>
        )}
      </div>
      <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <ContentTable screen={currentScreen} />
      </div>
    </DashboardLayout>
  );
};

export default ConfigurationDashboard;
