import { axiosInstance } from '../../utils/axios';
import {
  Button,
  Card,
  Divider,
  Flex,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Tooltip,
  Tour,
  notification,
  theme,
} from 'antd';
import { InfoCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useTitle } from '../../hooks/useTitle';
import { validateEmail } from '../../utils/validators';
import DashboardLayout from '../../components/layout/dashboard';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useDynamicRefs from '../../hooks/useDynamicRefs';
import { useDashBoardAddUserTour } from '../../hooks/tour/useDashboardAddUserTour';
import {
  DashboardPageContentContext,
  removeP,
} from '../../context/DashboardContentProvider';
import LoadingSpinner from '../../components/screening-steps/util/LoadingSpinner';

interface RegisterInputs {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  kvkNumber: string;
}

const { useToken } = theme;

export default function RegisterSubUserPage() {
  useTitle('Registreren');
  const [messageApi, contextHolder] = notification.useNotification();
  const { token } = useToken();
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState('researcher');
  const navigate = useNavigate();

  const radioOptions = [
    { label: 'Onderzoeker', value: 'researcher' },
    { label: 'Administrator', value: 'admin' },
  ];

  const content = useContext(DashboardPageContentContext);

  // tour variables
  const [getRef, setRef] = useDynamicRefs<HTMLDivElement>();

  const { tourSteps, tourOpen, setTourOpen } = useDashBoardAddUserTour(getRef);

  const onRadioChange = ({ target: { value } }: RadioChangeEvent) => {
    setRadioValue(value);
  };

  const handleSubmit = async (values: RegisterInputs) => {
    values.role = radioValue;
    axiosInstance
      .post('user/register/sub-user', values)
      .then((res) => {
        messageApi.success({
          message: 'Account aangemaakt',
          placement: 'bottomRight',
        });
      })
      .catch((err) => {
        if (err.response.status === 409) {
          return messageApi.error({
            message: 'Er bestaat al een account met dit email adres',
            placement: 'bottomRight',
          });
        }
        if (err.response?.status === 400) {
          if (
            err.response.data.message[0] ===
            'firstName must be longer than or equal to 2 characters'
          ) {
            return messageApi.error({
              message: 'Voornaam moet minimaal 2 karakters zijn',
              placement: 'bottomRight',
            });
          }
          if (
            err.response.data.message[0] ===
            'lastName must be longer than or equal to 2 characters'
          ) {
            return messageApi.error({
              message: 'Achternaam moet minimaal 2 karakters zijn',
              placement: 'bottomRight',
            });
          }
        }
        return messageApi.error({
          message: 'Er is iets misgegaan',
          placement: 'bottomRight',
        });
      });
  };

  return content === 'loading' ? (
    <LoadingSpinner />
  ) : (
    <DashboardLayout setTourOpen={setTourOpen}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '1rem',
          marginTop: '1rem',
        }}
      >
        {contextHolder}
        <div>
          <Row
            justify="start"
            style={{ marginBottom: '1rem', marginTop: '1rem' }}
          >
            <Button type="link" onClick={() => navigate('/dashboard/users')}>
              <ArrowLeftOutlined /> Terug
            </Button>
          </Row>
          <Card
            title={removeP(content.addUser.addUserTitle as string)}
            style={{ maxWidth: 500 }}
            ref={setRef('card')}
          >
            <div>{content.addUser.addUserDescription}</div>

            <Divider />

            <Form onFinish={handleSubmit} layout="vertical" form={form}>
              <Flex vertical ref={setRef('name')}>
                <Form.Item label="Naam" required>
                  <Input.Group compact>
                    <Form.Item
                      name="firstName"
                      noStyle
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Vul alstublieft een voornaam in.',
                        },
                        {
                          min: 2,
                          message:
                            'Voornaam moet minimaal 2 karakters bevatten.',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Voornaam"
                        style={{ width: '50%' }}
                        maxLength={2000}
                      />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      noStyle
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Vul alstublieft een achternaam in.',
                        },
                        {
                          min: 2,
                          message:
                            'Achternaam moet minimaal 2 karakters bevatten.',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Achternaam"
                        style={{ width: '50%' }}
                        maxLength={2000}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Flex>
              <Flex vertical ref={setRef('email')}>
                <Form.Item
                  name="email"
                  label="Email"
                  required
                  rules={[
                    {
                      validator: validateEmail,
                    },
                  ]}
                >
                  <Input
                    placeholder="email@provider.com"
                    autoComplete="email"
                    maxLength={2000}
                  />
                </Form.Item>
              </Flex>
              <Form.Item label="Soort gebruiker" name="role" required>
                <Radio.Group
                  options={radioOptions}
                  onChange={onRadioChange}
                  optionType="button"
                  buttonStyle="solid"
                  value={radioValue}
                  ref={setRef('role')}
                />
                <Tooltip
                  title={
                    <div>
                      <p>
                        <b>Wat is het verschil?</b>
                      </p>
                      <p>Onderzoeker:</p>
                      <ul>
                        <li>Kan een screening starten, inzien en eindigen.</li>
                        <li>Kan alle openstaande screenings zien.</li>
                        <li>
                          Kan{' '}
                          <b>
                            <u>geen</u>
                          </b>{' '}
                          andere gebruikers inzien.
                        </li>
                      </ul>
                      <p>Administrator:</p>
                      <ul>
                        <li>Heeft dezelfde rechten als de gebruiker, én</li>
                        <li>
                          Kan gebruikers en administrators aanmaken, aanpassen
                          en verwijderen.
                        </li>
                      </ul>
                    </div>
                  }
                  overlayStyle={{ maxWidth: '100%' }}
                  color={token.colorPrimary}
                >
                  <InfoCircleOutlined style={{ marginLeft: '1%' }} />
                </Tooltip>
              </Form.Item>
              <Form.Item
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  width: '100%',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                  ref={setRef('submit')}
                >
                  Aanmaken
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
      <Tour
        open={tourOpen}
        onClose={() => setTourOpen(false)}
        steps={tourSteps}
      />
    </DashboardLayout>
  );
}
