import DashboardLayout from '../../../components/layout/dashboard';
import { useTitle } from '../../../hooks/useTitle';
import EditUserComponent from '../../../components/EditUser';

const EditUser = () => {
  useTitle('Aanpassen gebruiker');
  return (
    <DashboardLayout>
      <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <EditUserComponent />
      </div>
    </DashboardLayout>
  );
};

export default EditUser;
