export enum StepKeys {
  notStarted = 'notStarted',
  personalData = 'personaDetails',
  identification = 'identification',
  bankData = 'bankData',
  incomeCheck = 'incomeCheck',
  kvk = 'kvk',
  kadaster = 'kadaster',
  bkr = 'bkr',
  landlordStatement = 'landlordStatement',
  employeeIntegrity = 'employeeIntegrity',
  education = 'education',
  certificateOfConduct = 'certificateOfConduct',
  confirmation = 'confirmation',
  completed = 'completed',
}

export enum Steps {
  notStarted = 'notStarted',
  personalData = 'personalData',
  identification = 'identification',
  bankData = 'bankData',
  incomeCheck = 'incomeCheck',
  kvk = 'kvk',
  kadaster = 'kadaster',
  bkr = 'bkr',
  landlordStatement = 'landlordStatement',
  employeeIntegrity = 'employeeIntegrity',
  education = 'education',
  certificateOfConduct = 'certificateOfConduct',
  confirmation = 'confirmation',
  completed = 'completed',
}

export enum stepTitles {
  notStarted = 'Nog niet gestart',
  personalData = 'Persoonlijke Info',
  identification = 'Legitimatie',
  bankData = 'Bankgegevens',
  incomeCheck = 'Inkomenscheck',
  kvk = 'KVK check',
  kadaster = 'Kadaster',
  bkr = 'BKR',
  landlordStatement = 'Verhuurdersverklaring',
  employeeIntegrity = 'Werknemersintegriteit',
  education = 'Opleidingscheck',
  certificateOfConduct = 'Verklaring Omtrent Gedrag',
  confirmation = 'Bevestiging',
  completed = 'Afgerond',
  referents = 'Referenten',
  interpretation = 'Duiding',
}
