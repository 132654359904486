import { useEffect, useContext, useState } from 'react';
import { useMessage } from '../../hooks/useMessage';
import {
  Form,
  Button,
  Card,
  Input,
  Typography,
  Upload,
  Tag,
  Modal,
  Divider,
} from 'antd';
import { uploadFile } from '../../utils/uploadFile';
import {
  UploadOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { CloseOutlined } from '@ant-design/icons';
import { axiosInstance } from '../../utils/axios';
import { useStep } from '../../hooks/useStep';
import { useTitle } from '../../hooks/useTitle';
import { DatePicker } from '../DatePicker';
import { DateTime } from 'luxon';
import { RcFile } from 'antd/es/upload';
import LoadingSpinner from './util/LoadingSpinner';
import CommentField from '../CommentField/CommentField';
import { centerPopup } from '../../utils/centerPopup';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { ScreeningContentContext } from '../../context/ScreeningContentProvider';

const { Paragraph } = Typography;
const { TextArea } = Input;

interface EducationItem {
  id?: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  diploma: string;
}

interface EducationFields {
  educations: EducationItem[];
  comment: string;
}

const { confirm } = Modal;

function EducationStep() {
  useTitle('Opleiding');
  const { toast } = useMessage();
  const { goToNextStep } = useStep();
  const [form] = Form.useForm();
  const [comment, setComment] = useState<string>('');
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [loadingData, setLoadingData] = useState(true);
  const content = useContext(ScreeningContentContext);
  const loadingContent = content === 'loading';
  const [loading, setLoading] = useState(false);
  const [submittingFile, setSubmittingFile] = useState(false);

  useEffect(() => {
    axiosInstance
      .get('screening/education/')
      .then((response) => {
        setComment(response.data.comment);
        if (
          !response.data ||
          !response.data.records ||
          response.data.records.length === 0
        ) {
          setLoadingData(false);
          return;
        }
        form.setFieldsValue({
          educations: response.data?.records.map((record: any) => ({
            ...record,
            startDate: DateTime.fromISO(record.startDate),
            endDate: DateTime.fromISO(record.endDate),
          })),
        });
      })
      .catch((error) => {
        toast.error('Er is iets fout gegaan, probeer het later opnieuw');
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, [form, toast]);

  async function submitEducation(data: EducationFields) {
    setLoading(true);
    try {
      await axiosInstance.post('screening/education/save', {
        educations: data.educations.map((record) => ({
          ...record,
          startDate: DateTime.fromISO(record.startDate).toISODate(),
          endDate: DateTime.fromISO(record.endDate).toISODate(),
        })),
      });
      toast.success('Opleidingen zijn succesvol opgeslagen');
      goToNextStep();
    } catch (error) {
      toast.error('Er is iets fout gegaan, probeer het later opnieuw');
      setLoading(false);
    }
  }

  async function getFileUrl() {
    const response = await axiosInstance.get('screening/education/file/url');
    return response.data;
  }

  async function deleteRecord(name: number) {
    const fields = form.getFieldsValue();
    const record = fields.educations[name];
    if (fields.educations.length === 1) {
      toast.error('Er moet minimaal 1 opleiding zijn');
      return false;
    }
    if (record?.id) {
      axiosInstance
        .delete(`screening/education/delete-record/${record.id}`)
        .catch(() => {
          toast.error('Er is iets fout gegaan, probeer het later opnieuw');
          return false;
        });
    }
    return true;
  }

  async function uploadEducationFile(
    file: File,
    url: string,
    path: string,
    fieldName: number,
  ) {
    try {
      setSubmittingFile(true);
      await uploadFile(file, url);

      const record = form.getFieldValue('educations')[fieldName];

      const res = await axiosInstance.put('screening/education/record', {
        id: record?.id,
        name: record?.name,
        description: record?.description,
        startDate: record?.startDate,
        endDate: record?.endDate,
        file: path,
      });
      const newRecord = res.data;

      form.setFieldsValue({
        educations: form
          .getFieldValue('educations')
          .map((item: any, index: number) => {
            if (index === fieldName) {
              return newRecord;
            }
            return item;
          }),
      });
    } catch (error) {
      toast.error('Er is iets fout gegaan, probeer het later opnieuw');
    } finally {
      setSubmittingFile(false);
    }
  }

  const proceedWithoutEducation = () => {
    confirm({
      title: 'Weet u zeker dat u zonder opleidingen door wilt gaan?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Als u zonder opleidingen doorgaat, kan dit gevolgen hebben voor uw aanvraag. Heeft u opeleidingen ingevuld? Dan zullen deze verwijderd worden.',
      okText: 'Ja, doorgaan',
      cancelText: 'Nee',
      onOk() {
        axiosInstance
          .put('screening/education/proceed')
          .then(() => goToNextStep())
          .catch(() => {
            toast.error(
              'Er is een fout opgetreden, probeer het later opnieuw.',
            );
          });
      },
      onCancel() {
        return;
      },
    });
  };

  const openDuo = () => {
    const duoWindow = centerPopup(
      !loadingContent ? (content.educationCheck.duoLink as string) : 'alt',
      '_blank',
      1080,
      800,
    );
    if (duoWindow) {
      duoWindow.focus();
    }
    if (!duoWindow) {
      toast.error(
        'Het openen van DUO is niet gelukt. Controleer uw pop-up instellingen.',
      );
    }
  };

  return loadingData || loadingContent ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Form
        style={{ marginTop: 20 }}
        form={form}
        onFinish={submitEducation}
        layout="vertical"
        initialValues={{
          comment: '',
          educations: [
            {
              name: '',
              description: '',
              startDate: '',
              endDate: '',
              diploma: '',
            },
          ],
        }}
      >
        <Card title={content.educationCheck.title} style={{ maxWidth: 500 }}>
          <Paragraph>{content.educationCheck.description ?? ''}</Paragraph>
          <Paragraph>
            Met de knop hieronder gaat u rechtstreeks naar de website van DUO om
            uw diploma's te downloaden.
          </Paragraph>

          <Button
            style={{ marginBottom: 20 }}
            onClick={() => openDuo()}
            type="primary"
          >
            Open DUO
          </Button>

          <Form.List name="educations">
            {(fields, { add, remove }) => (
              <div
                style={{
                  display: 'flex',
                  rowGap: 16,
                  flexDirection: 'column',
                }}
              >
                {fields.map((field) => (
                  <Card
                    size="small"
                    title={`Opleiding ${field.name + 1}`}
                    key={field.key}
                    extra={
                      <CloseOutlined
                        onClick={async () => {
                          const canDelete = await deleteRecord(field.name);
                          if (!canDelete) return;
                          remove(field.name);
                        }}
                      />
                    }
                  >
                    <Form.Item
                      label="Naam van de opleiding"
                      name={[field.name, 'name']}
                      rules={[
                        {
                          required: true,
                          message: 'Vul alsjeblieft een naam in',
                        },
                      ]}
                    >
                      <Input value={''} />
                    </Form.Item>

                    <Form.Item
                      label="Omschrijving"
                      name={[field.name, 'description']}
                    >
                      <TextArea value={''} maxLength={2000} />
                    </Form.Item>
                    <div
                      style={{ display: isMobile ? 'block' : 'flex', gap: 20 }}
                    >
                      <Form.Item
                        label="Startdatum"
                        name={[field.name, 'startDate']}
                        rules={[
                          {
                            required: true,
                            message: 'Vul alsjeblieft een startdatum in',
                          },
                        ]}
                      >
                        <DatePicker
                          format="DD-MM-YYYY"
                          placeholder="01-01-1990"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Einddatum"
                        name={[field.name, 'endDate']}
                        rules={[
                          {
                            required: true,
                            message: 'Vul alsjeblieft een einddatum in',
                          },
                        ]}
                      >
                        <DatePicker
                          format="DD-MM-YYYY"
                          placeholder="01-01-1990"
                        />
                      </Form.Item>
                    </div>

                    <Form.Item label="Upload diploma" name="fileUpload">
                      {form.getFieldValue('educations')[field.name]?.file ? (
                        <Tag
                          icon={<CheckCircleOutlined />}
                          color="success"
                          style={{ marginBottom: 10 }}
                        >
                          Diploma geüpload
                        </Tag>
                      ) : (
                        <Tag
                          icon={<ClockCircleOutlined />}
                          color="default"
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          Geen diploma geüpload
                        </Tag>
                      )}
                      <Upload
                        beforeUpload={async (file: RcFile) => {
                          const fileData = await getFileUrl();
                          await uploadEducationFile(
                            file,
                            fileData.url,
                            fileData.filePath,
                            field.name,
                          );
                          return false;
                        }}
                        accept=".pdf"
                        fileList={[]}
                        multiple={false}
                        maxCount={1}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          loading={submittingFile}
                        >
                          Selecteer bestand
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Card>
                ))}

                <Button
                  type="dashed"
                  onClick={() =>
                    add({
                      name: '',
                      description: '',
                      startDate: '',
                      endDate: '',
                      diploma: '',
                    })
                  }
                  block
                >
                  + Opleiding toevoegen
                </Button>
              </div>
            )}
          </Form.List>
          <Divider />
          <CommentField
            ApiUrl={'screening/education/comment'}
            comment={comment}
          />
        </Card>
        <Button
          type="link"
          htmlType="button"
          onClick={proceedWithoutEducation}
          style={{ float: 'left', marginTop: 20, marginBottom: 30 }}
        >
          Doorgaan zonder opleidingen
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            float: 'right',
            marginTop: 20,
            marginBottom: 30,
            marginRight: 10,
          }}
          loading={loading}
        >
          Volgende
        </Button>
      </Form>
    </div>
  );
}

export default EducationStep;
