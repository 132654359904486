import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Form, Button, Card, Radio, Input, Select, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { axiosInstance } from '../../utils/axios';
import { useStep } from '../../hooks/useStep';
import { validatePostcode } from '../../utils/validators';
import { useTitle } from '../../hooks/useTitle';
import { useMessage } from '../../hooks/useMessage';
import LoadingSpinner from './util/LoadingSpinner';
import CommentField from '../CommentField/CommentField';
import { ScreeningContentContext } from '../../context/ScreeningContentProvider';

const KadasterStep = forwardRef((props, ref) => {
  useTitle('Kadaster gegevens');
  const [form] = Form.useForm();
  const { toast } = useMessage();
  const { goToNextStep } = useStep();

  const [hasObject, setHasObject] = useState<Boolean>();
  const [loadingData, setLoadingData] = useState(true);
  const content = useContext(ScreeningContentContext);
  const loadingContent = content === 'loading';
  const [loading, setLoading] = useState(false);

  const [comment, setComment] = useState<string>('');

  interface KadasterObject {
    postcode: string;
    housenumber: string;
    propertyType: string;
    id?: string;
  }

  interface KadasterFields {
    hasObject: boolean;
    objects: KadasterObject[];
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    save() {
      const values = form.getFieldsValue();
      const touched = form.isFieldsTouched();
      if (!touched) {
        return;
      }
      form
        .validateFields({ validateOnly: true })
        .then(() => {
          handleSubmit(values, false);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  }));
  const fetchData = async () => {
    await axiosInstance
      .get('screening/kadaster')
      .then((response) => {
        if (response.data) {
          form.setFieldsValue({
            hasObject: response.data.hasObject,
            touched: false,
            objects: response.data.kadasterObjects.map(
              (obj: KadasterObject) => ({
                postcode: obj.postcode,
                housenumber: obj.housenumber,
                propertyType: obj.propertyType,
                id: obj.id,
              }),
            ),
          });
          setHasObject(response.data.hasObject);
          setComment(response.data.kadasterComment);
        }
      })
      .catch(() => {
        toast.error('Er is iets fout gegaan, probeer het later opnieuw');
      })
      .finally(() => setLoadingData(false));
  };
  const handleSubmit = (
    values: KadasterFields,
    shouldGoToNextStep: boolean,
  ) => {
    setLoading(true);
    if (values.hasObject && values.objects.length === 0) {
      toast.error('Vul alsjeblieft minimaal 1 object in');
      setLoading(false);
      return;
    }

    axiosInstance
      .post('screening/kadaster', {
        hasObject: values.hasObject,
        objects: values.objects,
      })
      .then(() => {
        setLoading(false);
        toast.success('Kadaster gegevens opgeslagen.');
        if (shouldGoToNextStep) {
          goToNextStep();
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error('Er is iets fout gegaan, probeer het later opnieuw');
      });
  };

  return loadingData || loadingContent ? (
    <LoadingSpinner />
  ) : (
    <>
      <div>
        <Form
          form={form}
          onFinish={(values) => handleSubmit(values, true)}
          layout="vertical"
          initialValues={{ objects: [{}] }}
        >
          <Card title={content.kadasterCheck.title} style={{ maxWidth: 500 }}>
            <Form.Item
              label={content.kadasterCheck.kadasterQuestion}
              name="hasObject"
              rules={[
                {
                  required: true,
                  message:
                    'Vul alsjeblieft in of je eigenaar bent van een object.',
                },
              ]}
            >
              <Radio.Group
                onChange={({ target }) => setHasObject(target.value)}
              >
                <Radio value={true}>Ja</Radio>
                <Radio value={false}>Nee</Radio>
              </Radio.Group>
            </Form.Item>
            {hasObject && (
              <Form.List name="objects">
                {(fields, { add, remove }) => (
                  <div
                    style={{
                      display: 'flex',
                      rowGap: 16,
                      flexDirection: 'column',
                    }}
                  >
                    {fields.map((field) => (
                      <Card
                        size="small"
                        title={`Object ${field.name + 1}`}
                        key={field.key}
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              if (fields.length > 1) {
                                remove(field.name);
                              } else {
                                toast.error(
                                  'Er moet minimaal 1 adres worden opgegeven',
                                );
                              }
                            }}
                          />
                        }
                      >
                        <Form.Item
                          label="Postcode:"
                          name={[field.name, 'postcode']}
                          rules={[
                            {
                              required: true,
                              message: 'Vul alsjeblieft een postcode in',
                            },
                            { validator: validatePostcode },
                          ]}
                        >
                          <Input maxLength={10} />
                        </Form.Item>

                        <Form.Item
                          label="Huisnummer:"
                          name={[field.name, 'housenumber']}
                          rules={[
                            {
                              required: true,
                              message: 'Vul alsjeblieft een huisnummer in',
                            },
                          ]}
                        >
                          <Input maxLength={10} />
                        </Form.Item>
                        <Form.Item
                          label="Object type:"
                          name={[field.name, 'propertyType']}
                          rules={[
                            {
                              required: true,
                              message:
                                'Vul alsjeblieft het type van het object in',
                            },
                          ]}
                        >
                          <Select
                            options={[
                              { value: 'house', label: 'Woning' },
                              { value: 'business', label: 'Bedrijf' },
                              { value: 'other', label: 'Anders' },
                            ]}
                          />
                        </Form.Item>
                      </Card>
                    ))}

                    <Button type="dashed" onClick={() => add()} block>
                      + Object toevoegen
                    </Button>
                  </div>
                )}
              </Form.List>
            )}
            <Divider />
            <CommentField
              ApiUrl={'screening/kadaster/comment'}
              comment={comment}
            />
          </Card>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              float: 'right',
              marginTop: 20,
              marginBottom: 30,
              marginRight: 10,
            }}
            loading={loading}
          >
            Volgende
          </Button>
        </Form>
      </div>
    </>
  );
});

export default KadasterStep;
