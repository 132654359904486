import { createContext, ReactNode, useEffect, useState } from 'react';
import {
  Content,
  ParsedScreeningContent,
  ScreeningContent,
} from '../shared/interfaces/screeningContent.interface';
import { defaultScreeningContent } from '../utils/defaultContent';
import { axiosInstance } from '../utils/axios';
import parse from 'html-react-parser';

export const ScreeningContentContext = createContext<
  ParsedScreeningContent | 'loading'
>('loading');

const fetchContent = async (): Promise<ScreeningContent> => {
  return axiosInstance
    .get('content/screening/NL')
    .then((result) => {
      return result.data;
    })
    .catch(() => defaultScreeningContent);
};

const parseContent = (content: ScreeningContent): ParsedScreeningContent => {
  const parsedContent: Partial<ParsedScreeningContent> = {}; // Use Partial to allow gradual assignment

  Object.keys(content).forEach((step) => {
    const stepKey = step as keyof ScreeningContent;
    parsedContent[stepKey] = {} as any; // Ensure type safety

    Object.keys(content[stepKey]).forEach((key) => {
      const keyName = key as keyof (typeof content)[typeof stepKey];
      const contentItem = content[stepKey][keyName] as Content;

      if (contentItem?.type === 'text') {
        parsedContent[stepKey]![keyName] = parse(contentItem.text);
      } else {
        parsedContent[stepKey]![keyName] = contentItem?.text ?? '';
      }
    });
  });

  return parsedContent as ParsedScreeningContent;
};

export const ScreeningContentProvider = (props: { children: ReactNode }) => {
  const [content, setContent] = useState<ParsedScreeningContent | 'loading'>(
    'loading',
  );

  useEffect(() => {
    fetchContent().then((screeningContent) =>
      setContent(parseContent(screeningContent)),
    );
  }, []);

  return (
    <ScreeningContentContext.Provider value={content}>
      {props.children}
    </ScreeningContentContext.Provider>
  );
};
