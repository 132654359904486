import { useEffect, useState } from 'react';
import { Button, Flex, Row, Statistic, Tour } from 'antd';
import ScreeningOverviewTable from '../../components/ScreeningOverviewTable/ScreeningOverviewTable';
import DashboardLayout from '../../components/layout/dashboard';
import { useTitle } from '../../hooks/useTitle';
import { axiosInstance } from '../../utils/axios';
import { valueType } from 'antd/es/statistic/utils';
import { useNavigate } from 'react-router-dom';
import useDynamicRefs from '../../hooks/useDynamicRefs';
import { useDashBoardHomeTour } from '../../hooks/tour/useDashboardHomeTour';
import { ShoppingCartOutlined } from '@ant-design/icons';

const DashboardHome = () => {
  const navigate = useNavigate();

  // tour variables
  const [getRef, setRef] = useDynamicRefs<HTMLElement>();
  const { tourSteps, tourOpen, setTourOpen } = useDashBoardHomeTour(getRef);

  const [credits, setCredits] = useState();

  useEffect(() => {
    axiosInstance
      .get('company/credits')
      .then((response) => {
        setCredits(response.data.credits);
      })
      .catch(() => {});
  }, [setCredits, getRef]);

  useTitle('Dashboard');

  const DutchNumberFormatter = (value: valueType) =>
    new Intl.NumberFormat('nl-NL').format(+value);

  return (
    <DashboardLayout setTourOpen={setTourOpen}>
      <Row
        justify="space-between"
        style={{ marginBottom: '1rem', marginTop: '1rem' }}
      >
        <Flex ref={setRef('credits')} gap={10} align="flex-end">
          <Statistic
            title="Credits"
            value={credits}
            formatter={(value) => <span>{DutchNumberFormatter(value)}</span>}
          />
          <div>
            <Button
              type="primary"
              icon={<ShoppingCartOutlined />}
              onClick={() => navigate('/dashboard/credit-store')}
            />
          </div>
        </Flex>
        <Button
          type="primary"
          onClick={() => navigate('/dashboard/start-screening')}
          ref={setRef('start-screening')}
        >
          Start screening
        </Button>
      </Row>
      <Flex vertical ref={setRef('screening-table')}>
        <ScreeningOverviewTable />
      </Flex>

      <Tour
        open={tourOpen}
        onClose={() => setTourOpen(false)}
        steps={tourSteps}
      />
    </DashboardLayout>
  );
};

export default DashboardHome;
