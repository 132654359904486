import PaymentStatusComponent from '../../../components/PaymentStatus';
import DashboardLayout from '../../../components/layout/dashboard';
import { useTitle } from '../../../hooks/useTitle';

const PaymentStatus = () => {
  useTitle('Betaling status');
  return (
    <DashboardLayout>
      <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <PaymentStatusComponent />
      </div>
    </DashboardLayout>
  );
};

export default PaymentStatus;
