import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Button, Input, Row, Table, Popconfirm } from 'antd';
import { EditOutlined, DeleteFilled } from '@ant-design/icons';
import { axiosInstance } from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../redux/services/auth.service';
import LoadingSpinner from '../screening-steps/util/LoadingSpinner';
import UserRole from '../../shared/enums/user-role.enum';
import { useMessage } from '../../hooks/useMessage';
import {
  DashboardPageContentContext,
  removeP,
} from '../../context/DashboardContentProvider';
import { ParsedDashboardPageContent } from '../../shared/interfaces/dashboardContent.interface';

interface DataSource {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  amountOfScreenings: number;
}

const UsersTable: React.FC = () => {
  const navigate = useNavigate();
  const { toast } = useMessage();
  const [dataSource, setDataSource] = useState<DataSource[]>([]);
  const [filteredDataSource, setFilteredDataSource] =
    useState<DataSource[]>(dataSource);
  const loggedInUser = AuthService.getCurrentUser();

  const [loadingData, setLoadingData] = useState(true);

  const content = useContext(DashboardPageContentContext);

  useEffect(() => {
    axiosInstance
      .get('/user/all')
      .then((res) => {
        setDataSource(res.data);
        setFilteredDataSource(res.data);
        setLoadingData(false);
      })
      .catch(() => {
        setLoadingData(false);
      });
  }, []);

  const deleteUser = (
    e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    userId: string,
  ) => {
    if (e) {
      e.stopPropagation();
    }
    axiosInstance
      .post('/user/delete', { userId: userId })
      .then(() => {
        setDataSource(
          dataSource.filter((item) => {
            return item.id !== userId;
          }),
        );

        setFilteredDataSource(
          filteredDataSource.filter((item) => {
            return item.id !== userId;
          }),
        );
      })
      .catch((reason) => {
        switch (reason?.response.data) {
          case 'User does not exist':
            toast.error('Gebruiker bestaat niet.');
            break;
          case 'Unable to delete user without being an admin user':
            toast.error(
              'Alleen administratoren kunnen gebruikers verwijderen.',
            );
            break;
          case 'Unable to delete main user':
            toast.error('De hoofdgebruiker kan niet verwijderd worden.');
            break;
          default:
            toast.error('Er is iets fout gegaan, probeer het later opnieuw.');
        }
      });
  };

  const columns = [
    {
      title: 'Naam',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      width: '38%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '38%',
    },
    {
      title: 'Rol',
      key: 'role',
      width: '18%',
      render: (record: any) => {
        const formatRole = (role: string, mainUser: boolean) => {
          if (mainUser) {
            return 'Hoofdgebruiker';
          }
          switch (role) {
            case UserRole.ADMIN:
              return 'Administrator';
            case UserRole.RESEARCHER:
              return 'Onderzoeker';
          }
        };

        return <div>{formatRole(record.role, record.mainUser)}</div>;
      },
    },
    {
      title: 'Aantal screenings',
      dataIndex: 'amountOfScreenings',
      key: 'amountOfScreenings',
      sorter: (a: any, b: any) =>
        Number(a.screeningsCreated) - Number(b.screeningsCreated),
      width: '18%',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '2%',
      render: (record: any) => {
        const showButton = !record.mainUser || loggedInUser?.id === record.id;
        return (
          <div>
            {showButton && (
              <Button
                onClick={() => navigate(`/dashboard/users/${record.id}`)}
                icon={<EditOutlined />}
                size="small"
                style={{ zIndex: 5 }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '2%',
      render: (record: any) => {
        const showButton =
          !record.mainUser && loggedInUser.role === UserRole.ADMIN;
        return (
          <div>
            {showButton && (
              <Popconfirm
                overlayStyle={{ width: '30em' }}
                title={removeP(
                  (content as ParsedDashboardPageContent).users
                    .deleteUserTitle as string,
                )}
                description={removeP(
                  (content as ParsedDashboardPageContent).users
                    .deleteUserDiscription as string,
                )}
                okText="Ja"
                cancelText="Nee"
                onConfirm={(e) => deleteUser(e, record.id)}
                onCancel={(e) => e?.stopPropagation()}
              >
                <Button
                  onClick={(e) => e.stopPropagation()}
                  danger
                  icon={<DeleteFilled />}
                  size="small"
                  style={{ zIndex: 5 }}
                ></Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  const onSearch = (value: string) => {
    const filteredData = dataSource.filter(
      (record) =>
        record.name.toLowerCase().includes(value.toLowerCase()) ||
        record.email.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredDataSource(filteredData);
    if (value === '') setFilteredDataSource(dataSource);
  };

  return loadingData || content === 'loading' ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Row
        justify="space-between"
        style={{ marginBottom: '1rem', marginTop: '1rem', gap: '1rem' }}
      >
        <Button
          type="primary"
          onClick={() => navigate('/dashboard/register/sub-user')}
        >
          Gebruiker toevoegen
        </Button>
        <Input.Search
          placeholder="Zoek gebruiker"
          style={{ width: 250 }}
          onChange={(e) => onSearch(e.target.value)}
        />
      </Row>
      <Table
        scroll={{ x: true }}
        dataSource={filteredDataSource}
        columns={columns}
        rowKey="id"
      />
    </div>
  );
};

export default UsersTable;
