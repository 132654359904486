import { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  Divider,
  theme,
  Descriptions,
  DescriptionsProps,
  Modal,
} from 'antd';
import { axiosInstance } from '../../utils/axios';
import { RedoOutlined } from '@ant-design/icons';
import { useStep } from '../../hooks/useStep';
import { useTitle } from '../../hooks/useTitle';
import { useMessage } from '../../hooks/useMessage';
import LoadingSpinner from './util/LoadingSpinner';
import CommentField from '../CommentField/CommentField';
import { ScreeningContentContext } from '../../context/ScreeningContentProvider';

const { useToken } = theme;

interface Result {
  checkType: string;
  employeeEmail: string;
  status: string;
  reasons: string[];
  initials: string;
  name: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  placeOfBirth: string;
  country: string;
  nationality: string;
  gender: string;
  passPhoto: string;
}

const LegitimatieStep = () => {
  useTitle('Legitimatie check');
  const { token } = useToken();
  const { goToNextStep } = useStep();
  const { toast } = useMessage();
  const candidate = JSON.parse(localStorage.getItem('candidate') || '{}');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [status, setStatus] = useState('nog niet aangevraagd');
  const [results, setResults] = useState<Result>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const content = useContext(ScreeningContentContext);
  const loadingContent = content === 'loading';
  const [loadingDataChecker, setLoadingDataChecker] = useState(false);
  const [comment, setComment] = useState<string>('');

  const items: DescriptionsProps['items'] = [
    {
      key: 1,
      label: 'Kandidaat email',
      children: candidate?.email,
      span: 2,
    },
    {
      key: 2,
      label: 'Kandidaat telefoon',
      children: candidate?.phoneNumber,
      span: 2,
    },
  ];

  const descriptionItems = [
    { label: 'Status', value: results?.status },
    { label: 'Redenen', value: results?.reasons.join(', ') },
    { label: 'Initialen', value: results?.initials },
    { label: 'Naam', value: results?.name },
    { label: 'Voornaam', value: results?.firstName },
    { label: 'Achternaam', value: results?.lastName },
    { label: 'Geboortedatum', value: results?.dateOfBirth },
    { label: 'Geboorteplaats', value: results?.placeOfBirth },
    { label: 'Land', value: results?.country },
    { label: 'Nationaliteit', value: results?.nationality },
    { label: 'Geslacht', value: results?.gender },
    { label: 'Pasfoto', value: <DisplayPassPhoto /> },
  ];

  let statusColor =
    status === 'geslaagd' ? ' green' : status === ' gefaald' ? 'red' : 'orange';

  const statusLookup: { [key: string]: string } = {
    approved: 'geslaagd',
    disapproved: 'geweigerd',
    'in process': 'in proces',
  };

  const onClick = async () => {
    setIsButtonDisabled(true);
    toast.open({
      content:
        'Verzoek is verstuurd naar de DataChecker, ogenblik geduld aub...',
      key: 'loading',
    });
    setStatus('verstuurd');
    await axiosInstance
      .post(`/screening/datachecker`, {
        id: candidate?.id,
      })
      .then((response) => {
        if (response.data === 'Conflict') {
          toast.error({
            type: 'error',
            content: 'Je hebt al een keer een mail en sms ontvangen',
          });
        } else {
          toast.open({
            type: 'success',
            content: 'Je hebt een email en sms binnen gekregen',
          });
          setStatus('in proces');
        }
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        toast.error({
          type: 'error',
          content: error.message,
        });
      });
  };

  const viewDatacheckerData = async () => {
    setIsModalVisible(true);
    const result = await axiosInstance.get(
      `/screening/datachecker/${candidate?.id}/results`,
    );
    setResults(result.data);
  };

  const setCorrectStatus = (status: string) => {
    const statusString = statusLookup[status];

    if (!statusString) {
      throw new Error(`Invalid status value: ${status}`);
    }
    setStatus(statusString);
  };

  const fetchData = async () => {
    setLoadingDataChecker(true);
    const result = await axiosInstance.get(
      `/screening/datachecker/${candidate?.id}/results`,
    );
    setComment(result?.data.candidateIdentificationComment ?? '');
    try {
      setCorrectStatus(result.data.status);
    } catch (TypeError) {
      setStatus('nog niet aangevraagd');
    }
    setLoadingDataChecker(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function DisplayPassPhoto() {
    return (
      <span>
        {results?.passPhoto ? (
          <img
            src={`data:image/jpeg;base64,${results?.passPhoto}`}
            alt="Pasfoto"
            style={{ width: '50%' }}
          />
        ) : (
          <span>Geen foto beschikbaar</span>
        )}
      </span>
    );
  }

  return loadingData || loadingContent ? (
    <LoadingSpinner />
  ) : (
    <>
      <div>
        <Card
          title={content.identification.title}
          style={{
            maxWidth: 500,
            backgroundColor: token.colorBgBase,
          }}
        >
          {content.identification.description}
          <Divider />
          <Descriptions items={items} layout="vertical" />
          <Divider />
          <p>
            Status:{' '}
            <span style={{ color: statusColor, fontWeight: 'bold' }}>
              {status}
            </span>
          </p>
          <div>
            <Button
              onClick={fetchData}
              style={{ backgroundColor: 'orange', color: 'white' }}
              loading={loadingDataChecker}
            >
              Vernieuw status
              <RedoOutlined />
            </Button>
            <Button
              onClick={viewDatacheckerData}
              type="default"
              style={{
                marginLeft: '10px',
                visibility:
                  status === 'geslaagd' || status === 'geweigerd'
                    ? 'visible'
                    : 'hidden',
              }}
            >
              Bekijk gegevens
            </Button>
          </div>
          {content.identification.dataCheckerRequestDetails}
          <Button onClick={onClick} disabled={isButtonDisabled} type="primary">
            Verstuur
          </Button>
          <Divider />
          <CommentField
            ApiUrl={'screening/datachecker/comment'}
            comment={comment}
          />
        </Card>
        <Button
          onClick={goToNextStep}
          style={{
            float: 'right',
            marginTop: 20,
            marginBottom: 30,
            marginRight: 10,
          }}
          type="primary"
        >
          Volgende
        </Button>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        style={{ top: 20 }}
        footer={[
          <Button key="close" onClick={() => setIsModalVisible(false)}>
            Sluiten
          </Button>,
        ]}
      >
        <Descriptions
          title="Legitimatie gegevens"
          layout="horizontal"
          column={1}
        >
          {descriptionItems.map((item) => (
            <Descriptions.Item label={item.label}>
              {item.value}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Modal>
    </>
  );
};

export default LegitimatieStep;
