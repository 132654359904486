import { StepKeys, Steps } from '../shared/enums/steps.enum';

export const availableSteps = [
  {
    key: StepKeys.personalData,
    title: 'Persoonlijke Info',
    url: Steps.personalData,
  },
  {
    key: StepKeys.identification,
    title: 'Legitimatie',
    url: Steps.identification,
  },
  {
    key: StepKeys.bankData,
    title: 'Bankgegevens',
    url: Steps.bankData,
  },
  {
    key: StepKeys.incomeCheck,
    title: 'Inkomenscheck',
    url: Steps.incomeCheck,
  },
  {
    key: StepKeys.kvk,
    title: 'KVK check',
    url: Steps.kvk,
  },
  {
    key: StepKeys.kadaster,
    title: 'Kadaster',
    url: Steps.kadaster,
  },
  {
    key: StepKeys.bkr,
    title: 'BKR',
    url: Steps.bkr,
  },
  {
    key: StepKeys.landlordStatement,
    title: 'Verhuurdersverklaring',
    url: Steps.landlordStatement,
  },
  {
    key: StepKeys.employeeIntegrity,
    title: 'Werknemersintegriteit',
    url: Steps.employeeIntegrity,
  },
  {
    key: StepKeys.education,
    title: 'Opleidingscheck',
    url: Steps.education,
  },
  {
    key: StepKeys.certificateOfConduct,
    title: 'Verklaring Omtrent Gedrag',
    url: Steps.certificateOfConduct,
  },
  {
    key: StepKeys.confirmation,
    title: 'Bevestiging',
    url: Steps.confirmation,
  },
];
